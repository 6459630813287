import { isArray, reject, uniq } from 'lodash';
import { editRegistrationService, transferRegistrationService } from 'modules/Distances/services';
import { havePaidCustomFields } from 'modules/Distances/utils';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import * as React from 'react';

import {
  DISTANCE_MODES,
  DISTANCE_STEPS,
  DISTANCE_TYPE,
  distanceStep,
  HIDDEN_DISTANCE_STEPS,
  START_TYPE,
  CONFIRM_POPUP_TYPES,
  POSSIBLE_CALENDAR_DISTANCE_STEPS,
} from 'src/constants';

import { ConfirmationModal } from 'components/ConfirmationModal';

import { t } from 'utils';

import { Race as RaceModel } from 'models';

import { helperDistancesStore, helperRacesStore } from 'stores';

import { deleteRelation, relationSteps, State as OriginalFormState } from '../../../../shared/stateHelpers';
import { DELETE_STEPS_CLEAN_UP } from '../../Steps';
import { Item } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback: Function,
  ) => void;
  errors: {
    [K in string]: Array<string>;
  };
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

type State = {
  onStepDeleteConfirm: nil | Function;
  onStepTransferConfirm: nil | Function;
  onStepGPX: nil | Function;
};

const TAB_OPTIONS = Object.keys(DISTANCE_STEPS);
const TABS_TO_DISPLAY = TAB_OPTIONS.filter((tabOption) => !HIDDEN_DISTANCE_STEPS.includes(tabOption as distanceStep));

class TabOptions extends React.Component<Props, State> {
  state = {
    onStepDeleteConfirm: null,
    onStepTransferConfirm: null,
    onStepGPX: null,
  };

  onChange = (fields: Array<string>, isNeedLiveUpdate = true) => {
    const { onChange, formData, liveUpdate } = this.props;
    isNeedLiveUpdate && liveUpdate({ editorSettings: { tab_options: fields } });
    onChange(
      {
        tab_options: fields,
      },
      'editorSettings',
      this._clearPopup,
    );
  };

  onChangeSelfService = (fields: Array<string>) => {
    const { onChange, formData, liveUpdate } = this.props;

    liveUpdate({ editorSettings: { tab_options: fields } });
    onChange(
      {
        tab_options: fields,
      },
      'editorSettings',
      this._clearPopup,
    );
  };

  onChangeWithDistance = (fields: Array<string>, distance: any, isNeedLiveUpdate = true) => {
    const { onChange, formData, liveUpdate } = this.props;
    const { editorSettings } = formData;

    isNeedLiveUpdate && liveUpdate({ editorSettings: { ...editorSettings, tab_options: fields } });
    onChange(
      {
        distance,
        editorSettings: { ...editorSettings, tab_options: fields },
      },
      '',
      this._clearPopup,
    );
  };

  onRelationDelete = (relation: string, fields: Array<string>, callback: Function = () => {}) => {
    const { onChange, formData, liveUpdate } = this.props;
    const cleanUp = DELETE_STEPS_CLEAN_UP[relation] || ((form: AnyFunction) => form);
    const newData = deleteRelation(formData[relation]);

    const cleanedFormData = cleanUp(formData);
    const newFormData = { ...cleanedFormData, [relation]: newData };

    liveUpdate({ ...newFormData, editorSettings: { ...newFormData.editorSettings, tab_options: fields } });
    onChange(newFormData, '', callback);
  };

  onChangeTabOption = (tabOption: string, value: boolean) => {
    const { formData, liveUpdate } = this.props;
    const { custom_fields, distance, editorSettings } = formData;
    let tabOptions = [...editorSettings.tab_options];
    const isRefundProtectStepEnabled = tabOptions.includes(DISTANCE_STEPS.refund_protect);

    if (value) {
      tabOptions = [...tabOptions, tabOption];
    } else {
      tabOptions = tabOptions.filter((option) => option !== tabOption);
    }

    if (value && tabOption === DISTANCE_STEPS.prices && !isRefundProtectStepEnabled) {
      tabOptions = [...tabOptions, DISTANCE_STEPS.refund_protect];
      distance.refund_protect_enabled = true;
      liveUpdate({ refund_protect_enabled: true });
    }

    if (!value && tabOption === DISTANCE_STEPS.prices && isRefundProtectStepEnabled && !havePaidCustomFields(custom_fields)) {
      tabOptions = tabOptions.filter((option) => option !== DISTANCE_STEPS.refund_protect);
      distance.refund_protect_enabled = false;
      liveUpdate({ refund_protect_enabled: false });
    }

    if (!value && tabOption === DISTANCE_STEPS.custom_fields && !editorSettings.tab_options.includes(DISTANCE_STEPS.prices)) {
      tabOptions = tabOptions.filter((option) => option !== DISTANCE_STEPS.refund_protect);
    }

    if (!value && tabOption === DISTANCE_STEPS.checkpoints) {
      tabOptions = tabOptions.filter((option) => option !== DISTANCE_STEPS.GPX);
    }

    if (!value) {
      return this.onRelationDelete(tabOption, _sortValuesForChange(tabOptions), () => {
        if (tabOption === DISTANCE_STEPS.selfServices) {
          if (distance?.id) {
            transferRegistrationService.delete(distance.id);
            editRegistrationService.delete(distance.id);
          }
          this.onChangeWithDistance(
            _sortValuesForChange(tabOptions),
            {
              ...distance,
              is_transfer_registration_available: false,
              isTransferRegistrationEdit: false,
              transfer_registration_settings: {
                from: '',
                to: '',
                price: '',
              },
              is_edit_registration_available: false,
              edit_registration_settings: {
                from: '',
                to: '',
              },

              isEditRegistrationEdit: false,
            },
            false,
          );
        } else {
          this.onChange(_sortValuesForChange(tabOptions), false);
        }
      });
    }

    if (tabOption === DISTANCE_STEPS.prices) {
      return this.onChangeWithDistance(_sortValuesForChange(tabOptions), distance);
    }

    if (tabOption === DISTANCE_STEPS.selfServices) {
      return this.onChangeSelfService(_sortValuesForChange(tabOptions));
    }

    this.onChange(_sortValuesForChange(tabOptions));
  };

  // Wrapper around onChange, to implement confirm popup
  onChangeTabOptionWrapper = (tabOption: string, value: boolean) => {
    const isCheckPointTabDisable = !this.props.formData.editorSettings.tab_options.includes(DISTANCE_STEPS.checkpoints);

    if (tabOption === DISTANCE_STEPS.selfServices && !value) {
      this.setState({
        onStepTransferConfirm: () => {
          this.onChangeTabOption(tabOption, value);
        },
      });

      return;
    }

    if (tabOption === DISTANCE_STEPS.GPX && value && isCheckPointTabDisable) {
      this.setState({
        onStepGPX: () => {},
      });

      return;
    }

    if (value || this._isRelationEmpty(tabOption)) {
      return this.onChangeTabOption(tabOption, value);
    }

    this.setState({
      onStepDeleteConfirm: () => {
        this.onChangeTabOption(tabOption, value);
      },
    });
  };

  _renderTabOptions = (): React.ReactNode => {
    const { editorSettings, distance } = this.props.formData;
    const { race } = this.props.helperData;
    const raceCurrency = race?.value.currency?.iso_code || '';
    let tabOptions = editorSettings.tab_options;

    const tabsToDisplay = TABS_TO_DISPLAY;

    if (distance.type === DISTANCE_TYPE.team) {
      return this._filterTabOptions(tabsToDisplay.filter((tab) => tab !== DISTANCE_STEPS.selfServices)).map((tabOption) => (
        <Item key={tabOption} name={tabOption} selected={tabOptions.includes(tabOption)} onChange={this.onChangeTabOptionWrapper} />
      ));
    }
    if (distance.type === DISTANCE_TYPE.team) {
      return this._filterTabOptions(tabsToDisplay.filter((tab) => tab !== DISTANCE_STEPS.selfServices)).map((tabOption) => (
        <Item key={tabOption} name={tabOption} selected={tabOptions.includes(tabOption)} onChange={this.onChangeTabOptionWrapper} />
      ));
    }

    return this._filterTabOptions(tabsToDisplay).map((tabOption) => (
      <Item key={tabOption} name={tabOption} selected={tabOptions.includes(tabOption)} onChange={this.onChangeTabOptionWrapper} />
    ));
  };

  _filterTabOptions = (options: string[]) => {
    const { formData } = this.props;
    const isVirtual = formData.distance.distance_mode !== DISTANCE_MODES.CLASSIC;

    const optionsToHide: any = [];

    if (helperRacesStore.selected?.selected_lang_codes.length === 0) {
      optionsToHide.push(DISTANCE_STEPS.translations);
    }

    if (isVirtual) {
      optionsToHide.push(DISTANCE_STEPS.checkpoints);
      optionsToHide.push(DISTANCE_STEPS.medical_assistants);
    }

    return reject<any>(options, (option) => optionsToHide.includes(option));
  };

  _clearPopup = () => {
    if (this.state.onStepDeleteConfirm) {
      this.setState({
        onStepDeleteConfirm: null,
      });
    }
    if (this.state.onStepTransferConfirm) {
      this.setState({
        onStepTransferConfirm: null,
      });
    }
    if (this.state.onStepGPX) {
      this.setState({
        onStepGPX: null,
      });
    }
  };

  _isRelationEmpty = (relation: string) => {
    const { formData } = this.props;
    const relationData = formData[relation];
    if (!relationData) {
      return true;
    }

    return !relationData.filter((el: AnyObject) => !el._delete).length;
  };

  _hasActiveTransferRefistrations = () => Boolean(helperDistancesStore.selected?.active_transfer_registrations_count);

  render() {
    const { onStepDeleteConfirm, onStepTransferConfirm, onStepGPX } = this.state;
    return (
      <div className='tabOptionsForm form-content-wrapper'>
        <ConfirmationModal
          open={Boolean(onStepDeleteConfirm)}
          onConfirm={onStepDeleteConfirm || (() => {})}
          onClose={this._clearPopup}
          title={t.staticAsString('distances.steps.tabOptionsForm.confirmPopup.mainTitle')}
          body={t.staticAsString('distances.steps.tabOptionsForm.confirmPopup.mainBody')}
        />
        <ConfirmationModal
          open={Boolean(onStepTransferConfirm)}
          onConfirm={onStepTransferConfirm || (() => {})}
          onClose={this._clearPopup}
          title={t.staticAsString('distances.confirmCloseTitle')}
          body={t.staticAsString(
            `distances.steps.${
              this._hasActiveTransferRefistrations() ? 'selfServicesForm.confirmBody' : 'tabOptionsForm.confirmPopup.mainBody'
            }`,
          )}
        />

        <ConfirmationModal
          open={Boolean(onStepGPX)}
          onConfirm={this._clearPopup}
          onClose={this._clearPopup}
          title={t.staticAsString('distances.confirmPopup.gpxTitle')}
          body={t.staticAsString('distances.confirmPopup.gpxBody')}
          type={CONFIRM_POPUP_TYPES.info}
        />

        {this._renderTabOptions()}
        {this.props.controls}
      </div>
    );
  }
}

// Logic, to add/remove options from another components
const addOption = (values: Array<string>, option: string) => {
  const newValues = [...values, option];
  return _sortValuesForChange(newValues);
};

const removeOption = (values: Array<string>, option: string | Array<string>) => {
  const newValues = values.filter((el) => (isArray(option) ? !option.includes(el) : el !== option));
  return _sortValuesForChange(newValues);
};

const _sortValuesForChange = (values: Array<string>): Array<string> => {
  const newValues = [...values];
  newValues.sort((prevEl, nextEl) => TAB_OPTIONS.indexOf(prevEl) - TAB_OPTIONS.indexOf(nextEl));

  return newValues;
};

const generateOptionsBasedOnData = (data: State | any): Array<string> => {
  const defaultTabOptions = isCalendarRaceType.get()
    ? [DISTANCE_STEPS.nameAndDescription, DISTANCE_STEPS.distanceDetails]
    : [DISTANCE_STEPS.nameAndDescription, DISTANCE_STEPS.confirmationEmail, DISTANCE_STEPS.registrationFields, DISTANCE_STEPS.tabOptions];

  const relations = Object.keys(relationSteps);

  let generatedTabOptions = relations.reduce((acc: string[], relation: string) => {
    const isRelationPresent = data[relation] && data[relation].length;
    if (isRelationPresent) {
      return [...acc, relationSteps[relation]];
    }
    return acc;
  }, defaultTabOptions);

  if (data.prices.length) {
    generatedTabOptions.push(DISTANCE_STEPS.refund_protect);
  }

  if (!data.prices.length && data.editorSettings.tab_options.includes(DISTANCE_STEPS.prices) && !isCalendarRaceType.get()) {
    generatedTabOptions = [...generatedTabOptions, DISTANCE_STEPS.prices, DISTANCE_STEPS.refund_protect];
  }

  if (data.distance.start_type === START_TYPE.wave) {
    generatedTabOptions.push(DISTANCE_STEPS.waves);
  }

  if ((helperRacesStore.selected?.selected_lang_codes?.length ?? 0) > 1) {
    generatedTabOptions.push(DISTANCE_STEPS.translations);
  }

  if (isCalendarRaceType.get()) {
    generatedTabOptions = generatedTabOptions.filter((option) => POSSIBLE_CALENDAR_DISTANCE_STEPS.includes(option as distanceStep));
  }

  return _sortValuesForChange(uniq(generatedTabOptions));
};

export { TabOptions, addOption, removeOption, generateOptionsBasedOnData };
