import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { isEmpty, intersection } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES, ORDER_REGISTRATION_FIELDS, START_LIST } from 'src/constants';

import { oneFieldValidation, t } from 'utils';

import { Errors as ErrorsStore, confirmationModalStore, errorsStore } from 'stores';

import { racerStatus } from '../../../utils';

import memberService from '../../../services/membersService';

import { PaymentStatus } from '../../../../Payments/constants';
import { racerFields } from '../../../Filters';
import { memberValidation } from '../../../validations';
import { Controls } from '../../Controls';
import { CustomFields } from '../../Fields/CustomFields';
import { PersonalFields } from '../../Fields/PersonalFields';
import { RegisteredFields } from '../../Fields/RegisteredFields';
import { RenderPaymentStatus } from '../../Shared/RenderPaymentStatus';
import { Prices } from './Prices';
import { MAIN_COLUMNS } from './THead/columns';

type Props = {
  item: RacerType;
  errors?: ErrorsStore;
  isEdit?: boolean;
  distance: DistanceType;
  checkedRequiredFields: AnyObject;
  changedFields?: AnyObject;
  editRacer?: (racerId: number) => void;
  deleteRacer?: (item: RacerType) => void;
  handleCopy: (racerId: number) => Promise<unknown>;
  handleResend: (racerId: number, isSuccesfulEmail: boolean) => void;
  handleAttachToProfile: (racer: RacerType) => void;
  handleDeactivateRefundProtect: (racerId: number) => void;
  onChange?: (name: string, value: any, changedValue: any, callback: Function) => void;
  cancelEdit?: () => void;
  saveRacer?: () => void;
  isRefundProtectPaid: RacerType[];
};

type State = {
  racer: RacerType | AnyObject;
  changedFields: AnyObject;
  isEdit: string | null;
  isEditField: AnyObject | null;
};

const action = `UPDATE_${START_LIST}`;

const FIELD_MAPPING = {
  [racerFields['team.name']]: 'team_id',
};

@observer
class Item extends React.Component<Props, State> {
  state: State = {
    racer: {},
    changedFields: {},
    isEdit: null,
    isEditField: null,
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isEdit } = this.state;

    if (isEdit && !prevState.isEdit) {
      document.addEventListener('keyup', this.handlePressEnter as AnyFunction);
    }
  }

  handlePressEnter: React.KeyboardEventHandler = (e: React.KeyboardEvent) => {
    const { isEdit, isEditField } = this.state;
    const { distance } = this.props;

    if (e.keyCode === 13) {
      const isCustomField = (distance.custom_fields || []).some((item) => item.id === isEditField?.id);

      isCustomField ? this.saveCustomField() : this.saveRacer(String(isEdit));
    }
  };

  getRequiredFields = (): Array<React.ReactNode> => {
    const { distance, item, errors } = this.props;
    const { racer, isEdit, changedFields } = this.state;

    return this._additionalFields().map((field) => (
      <TableCell
        className={classNames('cell')}
        key={`additionalField-${field}${item.id}`}
        onDoubleClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          this.editRacer(field);
        }}
      >
        <RegisteredFields
          field={field}
          item={isEdit ? (racer as any) : (item as any)}
          isEdit={isEdit}
          onChange={this.onChange}
          changedFields={changedFields}
          saveRacer={this.saveRacer}
          distance={distance}
        />
      </TableCell>
    ));
  };

  showCustomFields = (): any => {
    const { distance, item } = this.props;
    const { racer, isEdit, changedFields } = this.state;

    const currentRacer: AnyObject = isEdit ? racer : item;

    const handleConfirm = (cf: RacerCustomFieldValue) => {
      confirmationModalStore.openModal({
        title: 'Remove field',
        body: 'This custom field will be removed from the racer. Are you sure?',
        type: CONFIRM_POPUP_TYPES.confirm,
        onConfirm: () => memberService.deleteCustomField(cf, currentRacer as RacerType),
      });
    };
    return (distance.custom_fields || []).map((field) => {
      const racersField = ((currentRacer && currentRacer.fields) || []).filter((itemField: AnyObject) => itemField.field_id === field.id);
      const isEmpty = racersField.length < 1 || (field.type === 'textfield' && racersField[0].value === '');
      return (
        <TableCell
          onDoubleClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
            this.editRacer(field.name, field);
          }}
          className={classNames('cell')}
          key={`customField-${field.id}`}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: isEmpty ? 'flex-start' : 'space-between' }}>
            <CustomFields
              item={currentRacer as any}
              isEdit={isEdit}
              distancesField={field}
              distance={distance}
              onChange={this.onChange}
              changedFields={changedFields}
              saveChanges={this.saveCustomField}
            />
            {!isEmpty && (
              <IconButton onClick={() => handleConfirm(field)}>
                <DeleteIcon style={{ color: 'lightgray' }} />
              </IconButton>
            )}
          </div>
        </TableCell>
      );
    });
  };

  renderRefundProtect = (): any => {
    const { distance, item, isRefundProtectPaid } = this.props;
    const { racer, isEdit } = this.state;
    const currentRacer: AnyObject = isEdit ? racer : item;

    if (!distance.refund_protect_enabled) {
      return null;
    }

    const renderMessage = () => {
      if (currentRacer.order_refund_protect_amount) {
        return `${t.staticAsString('racers.list.yes')} (${+(currentRacer.order_refund_protect_amount / 100).toFixed(2)} ${
          currentRacer.order_currency
        })`;
      }

      return t.staticAsString('racers.list.no');
    };

    return (
      <TableCell className={classNames('cell')} key={`${item.id}`}>
        {renderMessage()}
      </TableCell>
    );
  };

  renderFields = (fields: Array<any>): React.ReactNode => {
    const { item, distance, errors } = this.props;
    const { isEdit, racer, changedFields } = this.state;

    const fieldsToRender = fields.filter((field) => this._isFieldShown(field));

    return fieldsToRender.map((fieldToRender) => {
      const field = FIELD_MAPPING[fieldToRender] || fieldToRender;
      return (
        <TableCell
          className={classNames('cell')}
          key={`fields-${field}-${item.id}`}
          onDoubleClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
            this.editRacer(field);
          }}
        >
          <PersonalFields
            field={field}
            item={isEdit ? (racer as any) : (item as any)}
            isEdit={isEdit}
            onChange={this.onChange}
            distance={distance}
            saveRacer={this.saveRacer}
            errors={errors || {}}
          />
        </TableCell>
      );
    });
  };

  editRacer = (field: string | null, fieldObject: AnyObject | null = null): void => {
    const { isEdit } = this.state;

    if (isEdit === field) {
      return;
    }

    this.setState({
      ...this.state,
      racer: this.props.item,
      isEdit: field,
      isEditField: fieldObject,
      changedFields: {},
    });
  };

  cancelEdit = () => {
    this.setState({
      isEdit: null,
      isEditField: null,
      racer: {},
      changedFields: {},
    });
  };

  saveRacer = async (field: string) => {
    const { racer, changedFields } = this.state;

    if (!field) {
      return this.cancelEdit();
    }

    const constrainsField = { [field]: memberValidation[field] };
    const isValidate = oneFieldValidation(action, this.state.changedFields, constrainsField);

    if (!isValidate || isEmpty(changedFields) || !racer.id) {
      return this.cancelEdit();
    }

    const status = await memberService.updateResource(changedFields as any, racer as any);

    if (status) {
      document.removeEventListener('keyup', this.handlePressEnter as AnyFunction);
    }

    errorsStore.clearFrontendError(action);
  };

  saveCustomField = async () => {
    const { racer, changedFields } = this.state;

    if (isEmpty(changedFields) || !racer.id) {
      return this.cancelEdit();
    }

    const changedCustomField = changedFields.fields[0];

    const status = await memberService.updateCustomField(changedCustomField, racer as any);

    if (status) {
      document.removeEventListener('keyup', this.handlePressEnter as AnyFunction);
    }
  };

  onChange = (name: string, value: any, changedValue: any, callback: Function = () => {}): void => {
    this.setState(
      {
        ...this.state,
        racer: {
          ...this.state.racer,
          [name]: value,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: changedValue,
        },
      },
      () => {
        const constrainsField = { [name]: memberValidation[name] };

        oneFieldValidation(action, this.state.racer, constrainsField);

        callback && callback();
      },
    );
  };

  priceColumns = (item: RacerType) => {
    const { distance } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    if (!hasPrices) {
      return [];
    }

    return <Prices value={item} />;
  };

  isSuccesfulEmail = (racer: RacerType) => {
    if (racer.payment_required) {
      return false;
    }
    if (racer.is_team_leader || racer.is_team_leader === null) {
      return true;
    }
    if (racer.order_id !== null && racer.status === 'paid') {
      return true;
    }
    return false;
  };

  _additionalFields = () => {
    const { checkedRequiredFields } = this.props;
    const registrationFields: Array<string> = Object.keys(checkedRequiredFields).filter((filter) => !!checkedRequiredFields[filter]);

    return intersection<string>(ORDER_REGISTRATION_FIELDS, registrationFields);
  };

  _isFieldShown = (field: string) => {
    const { distance, checkedRequiredFields } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    switch (field) {
      case racerFields['class_id']:
        return !!distance.classes?.length;
      case racerFields['comment']:
        return true;
      case racerFields['discipline_id']:
        return !!distance.disciplines?.length;
      case racerFields['country_id']:
        return !!checkedRequiredFields['country_id'];
      case racerFields['nationality_id']:
        return !!checkedRequiredFields['nationality_id'];
      case racerFields['city']:
        return !!checkedRequiredFields['city'];
      case racerFields['external_swimrun_id']:
        return !!checkedRequiredFields['external_swimrun_id'];
      case racerFields['order_id']:
      case racerFields['order_created_at']:
      case racerFields['order_total']:
      case racerFields['order_status']:
        return !!hasPrices;
      default:
        return true;
    }
  };

  render() {
    const { item, handleCopy, handleResend, deleteRacer, handleDeactivateRefundProtect, handleAttachToProfile } = this.props;
    const isAwaiting = racerStatus.isAwaiting(item);

    const isCashUnpaid = item.payment_status === PaymentStatus.onSite;
    return (
      <TableRow
        sx={{
          backgroundColor: 'white',
        }}
        className={classNames({ awaiting: isAwaiting || isCashUnpaid })}
        id={item.id.toString()}
      >
        <TableCell className={classNames('cell')}>
          <Controls
            isSuccesfulEmail={this.isSuccesfulEmail(item)}
            isAwaiting={isAwaiting}
            isRefundProtectPaid={!!item.order_refund_protect_amount}
            isAttachToProfile
            teamStatus={String(item.team_status)}
            handleCopy={() => handleCopy(item.id)}
            handleDelete={() => deleteRacer && deleteRacer(item)}
            handleAttachToProfile={() => handleAttachToProfile(item)}
            handleResend={() => handleResend(item.id, this.isSuccesfulEmail(item))}
            handleDeactivateRefundProtect={() => handleDeactivateRefundProtect(item.id)}
          />
        </TableCell>
        {this.renderFields(MAIN_COLUMNS)}
        {this.getRequiredFields()}
        {this.showCustomFields()}
        {this.renderRefundProtect()}
        {this.renderFields([racerFields['external_swimrun_id']])}
        {this.renderFields([racerFields['added_at']])}
        {this.renderFields([racerFields['added_by']])}
        {this.priceColumns(item)}
        {this.renderFields([racerFields['comment']])}
        <RenderPaymentStatus
          item={item}
          onConfirm={() => memberService.togglePaymentStatus({ payment_status: PaymentStatus.paid } as any, item)}
        />
      </TableRow>
    );
  }
}

export { Item };
