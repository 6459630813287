import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { TableCell } from '@mui/material';
import classNames from 'classnames';
import { Observer } from 'mobx-react';

import { t } from 'utils';

import { CONFIRM_POPUP_TYPES, RACER } from '../../../../constants';

import { progressStore } from '../../../../stores';
import { confirmationModalStore } from '../../../../stores';

import { PaymentStatusLabels } from '../../../Payments/constants';
import { PaymentStatus } from '../../../Payments/constants';

type Props = {
  item: RacerType;
  onConfirm: () => void;
};

export const RenderPaymentStatus = ({ item, onConfirm }: Props): JSX.Element => {
  const label = PaymentStatusLabels[item.payment_status as PaymentStatus];
  if (item.payment_status === PaymentStatus.onSite && item.status !== 'paid') {
    const handleConfirm = async () => {
      confirmationModalStore.openModal({
        title: t.staticAsString('races.confirmPopup.mainTitle'),
        body: 'Are you sure you want to mark this racer as paid?',
        type: CONFIRM_POPUP_TYPES.confirm,
        onConfirm: () => onConfirm(),
      });
    };
    return (
      <Observer
        render={() => {
          const isLoading = progressStore.isLoading(`TOGGLE_PAYMENT_STATUS_${RACER}_${item.id}`);
          return (
            <TableCell className={classNames('cell')}>
              <Button disabled={isLoading} onClick={handleConfirm} size='small' variant='outlined' color='success'>
                {isLoading && <CircularProgress color='inherit' size={16} sx={{ marginRight: 1 }} />} Confirm
              </Button>
            </TableCell>
          );
        }}
      />
    );
  }
  return <TableCell className={classNames('cell')}>{label}</TableCell>;
};
