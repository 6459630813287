import defaultRaceImage from 'img/default-race-image.png';

export type distanceStep =
  | 'distanceDetails'
  | 'registrationFields'
  | 'tabOptions'
  | 'prices'
  | 'checkpoints'
  | 'classes'
  | 'disciplines'
  | 'custom_fields'
  | 'selfServices'
  | 'waves'
  | 'medical_assistants'
  | 'translations'
  | 'refund_protect'
  | 'GPX'
  | 'nameAndDescription'
  | 'confirmationEmail';

export const DISTANCE_STEPS: { [key in distanceStep]: distanceStep } = {
  nameAndDescription: 'nameAndDescription',
  distanceDetails: 'distanceDetails',
  confirmationEmail: 'confirmationEmail',
  registrationFields: 'registrationFields',
  tabOptions: 'tabOptions',
  prices: 'prices',
  checkpoints: 'checkpoints',
  classes: 'classes',
  disciplines: 'disciplines',
  custom_fields: 'custom_fields',
  selfServices: 'selfServices',
  waves: 'waves',
  medical_assistants: 'medical_assistants',
  translations: 'translations',
  refund_protect: 'refund_protect',
  GPX: 'GPX',
};

export const HIDDEN_DISTANCE_STEPS = [
  DISTANCE_STEPS.distanceDetails,
  DISTANCE_STEPS.nameAndDescription,
  DISTANCE_STEPS.confirmationEmail,
  DISTANCE_STEPS.registrationFields,
  DISTANCE_STEPS.tabOptions,
  DISTANCE_STEPS.waves,
  DISTANCE_STEPS.refund_protect,
  DISTANCE_STEPS.translations,

  /**
   * TODO, NOTE
   * Temporarily disable self services and transfer registration feature
   * it is done in order to merge feature into the developmen branch and deliver but deliver disabled
   */
  // DISTANCE_STEPS.selfServices,
];

export const POSSIBLE_CALENDAR_DISTANCE_STEPS = [DISTANCE_STEPS.distanceDetails, DISTANCE_STEPS.nameAndDescription, DISTANCE_STEPS.waves];

export const START_TYPE = {
  mass: 'mass',
  wave: 'wave',
  one_by_one: 'one_by_one',
};

export const DISTANCE_TYPE: { [x in 'single' | 'team']: 'single' | 'team' } = {
  single: 'single',
  team: 'team',
};

export const enum RegistrationFields {
  firstname = 'firstname',
  lastname = 'lastname',
  gender = 'gender',
  email = 'email',
  birthday = 'birthday',
  nationality_id = 'nationality_id',
  telephone = 'telephone',
  address = 'address',
  post_code = 'post_code',
  city = 'city',
  country_id = 'country_id',
  union = 'union',
  squad_id = 'squad_id',
  coupon_code = 'coupon_code',
  emergency_name = 'emergency_name',
  emergency_phone = 'emergency_phone',
  shoe_size = 'shoe_size',
  shirt_size = 'shirt_size',
  company = 'company',
  external_swimrun_id = 'external_swimrun_id',
}

export const REGISTRATION_FIELDS: Array<string> = [
  RegistrationFields.firstname,
  RegistrationFields.lastname,
  RegistrationFields.gender,
  RegistrationFields.email,
  RegistrationFields.telephone,
  RegistrationFields.birthday,
  RegistrationFields.nationality_id,
  RegistrationFields.city,
  RegistrationFields.address,
  RegistrationFields.post_code,
  RegistrationFields.country_id,
  RegistrationFields.union,
  RegistrationFields.squad_id,
  RegistrationFields.coupon_code,
  RegistrationFields.emergency_name,
  RegistrationFields.emergency_phone,
  RegistrationFields.shoe_size,
  RegistrationFields.shirt_size,
  RegistrationFields.company,
  RegistrationFields.external_swimrun_id,
];

export const HIDDEN_FOR_TEAM_FIELDS = ['squad_id'];

export const DISABLED_BY_DEFAULT_REGISTRATION_FIELDS = ['email', 'firstname', 'lastname', 'gender', 'telephone'];

export const SELECTED_BY_DEFAULT_REGISTRATION_FIELDS = [
  'email',
  'firstname',
  'lastname',
  'gender',
  'telephone',
  'birthday',
  'nationality_id',
  'city',
  'union',
  'post_code',
  'address',
];

export const ORDER_REGISTRATION_FIELDS = [
  RegistrationFields.address,
  RegistrationFields.post_code,
  RegistrationFields.company,
  RegistrationFields.union,
  RegistrationFields.squad_id,
  RegistrationFields.birthday,
  RegistrationFields.nationality_id,
  RegistrationFields.telephone,
  RegistrationFields.shoe_size,
  RegistrationFields.shirt_size,
  RegistrationFields.emergency_name,
  RegistrationFields.emergency_phone,
];

export const CUSTOM_FIELD_TYPES = [
  'textfield',
  'drop_down',
  'checkbox',
  'radio',
  'date',
  'time',
  'extra',
  /* Fields, that is not included at the moment
    'multiple',
    'date_time',
  */
];

export const CUSTOM_FIELDS_WITH_VALUES = ['drop_down', 'checkbox', 'radio', 'multiple', 'extra'];

export const BACKEND_DATE_FORMATE = 'YYYY-MM-DD';
export const BACKEND_DATETIME_FORMATE = 'YYYY-MM-DD HH:mm:ss';
export const BACKEND_DATETIME_MILLIS_FORMATE = 'YYYY-MM-DD HH:mm:ss.SSS';
export const STARTLIST_DATE_FORMATE = 'DD-MM-YYYY';
export const FRONTEND_DATE_FORMAT = 'DD/MM/YYYY';
export const FRONTEND_TIME_FORMAT = 'HH:mm:ss';
export const BACKEND_TIME_FORMAT = 'HH:mm:ss';

// Deprecated
export const GENDER = 'gender';
export const COUNTRY_ID = 'country_id';
export const NATIONALITY_ID = 'nationality_id';
export const ADDED_AT = 'added_at';

export const RACE_STATUSES = {
  awaiting: 'awaiting',
  reject: 'reject',
  approve: 'approve',
  draft: 'draft',
};

export const RACE_STATUSES_INT = {
  awaiting: 1,
  reject: 2,
  approve: 3,
  draft: 4,
};

export const CONFIRM_POPUP_TYPES = {
  confirm: 'confirm',
  info: 'info',
  delete: 'delete',
};

export const MESSAGE_POPUP_TYPES = {
  message: 'message',
  info: 'info',
};

export const DRAG_IDS = {
  CUSTOM_FIELDS: 'CUSTOM_FIELDS',
  CUSTOM_FIELD_VALUES: 'CUSTOM_FIELD_VALUES',
  DISTANCES: 'DISTANCES',
  WAVES: 'WAVES',
  CLASSES: 'CLASSES',
};

export const SORT_BY: { asc: 'ASC'; desc: 'DESC' } = {
  asc: 'ASC',
  desc: 'DESC',
};

export const RACE_IMAGE_ASPECT_RATIO = {
  width: 16,
  height: 9,
  ratio: 16 / 9,
};

export const EXTRA_CUSTOM_FIELD_IMAGE_RATIO = 1;

export const DISTANCE_STATUSES: {
  [K in distanceStatusType]: distanceStatusType;
} = {
  ready: 'ready',
  started: 'started',
  finished: 'finished',
  canceled: 'canceled',
};

export const PAYMENT_REQUIRED_STATUS = 'payment_required';

export const EXPORT_DATA = {
  metaDataLimit: 1000000,
  defaultMetaData: 5000,
  metaDataLoadingRatio: 500,
};

export const EXPORT_DATA_SIZES = {
  development: 8000,
  production: 20000,
  staging: 20000,
};

export const DEFAULT_RACE_IMAGE = defaultRaceImage;

export const REFERRAL_PROGRAM_URL = 'https://organizer.raceid.com/referral-program/';

export const BOOK_DEMO_MEETING_URL = 'https://meetings.hubspot.com/andreas-mathson';
export const HINT_QUERY = 'withHint';
