import { PaymentColumns } from '../types';

export const COLUMN_NAMES: { [K in PaymentColumns]: PaymentColumns } = {
  id: 'id',
  ref_number: 'ref_number',
  payment_external_id: 'payment_external_id',
  created_at: 'created_at',
  created_time: 'created_time',
  organizer_id: 'organizer_id',
  race_id: 'race_id',
  race_title: 'race_title',
  distance_id: 'distance_id',
  distance_title: 'distance_title',
  email: 'email',
  firstname: 'firstname',
  lastname: 'lastname',
  gender: 'gender',
  birthday: 'birthday',
  address: 'address',
  post_code: 'post_code',
  city: 'city',
  country_id: 'country_id',
  distance_original_price: 'distance_original_price',
  discount: 'discount',
  custom_fields_sum: 'custom_fields_sum',
  total: 'total',
  distance_vat_value: 'distance_vat_value',
  status: 'status',
  receipt: 'receipt',
};

export const COLUMNS: Array<PaymentColumns> = [
  COLUMN_NAMES.id,
  COLUMN_NAMES.ref_number,
  COLUMN_NAMES.payment_external_id,
  COLUMN_NAMES.created_at,
  COLUMN_NAMES.created_time,
  COLUMN_NAMES.organizer_id,
  COLUMN_NAMES.race_id,
  COLUMN_NAMES.race_title,
  COLUMN_NAMES.distance_id,
  COLUMN_NAMES.distance_title,
  COLUMN_NAMES.email,
  COLUMN_NAMES.firstname,
  COLUMN_NAMES.lastname,
  COLUMN_NAMES.gender,
  COLUMN_NAMES.birthday,
  COLUMN_NAMES.address,
  COLUMN_NAMES.post_code,
  COLUMN_NAMES.city,
  COLUMN_NAMES.country_id,
  COLUMN_NAMES.distance_original_price,
  COLUMN_NAMES.distance_vat_value,
  COLUMN_NAMES.discount,
  COLUMN_NAMES.custom_fields_sum,
  COLUMN_NAMES.total,
  COLUMN_NAMES.status,
  COLUMN_NAMES.receipt,
];

export const COLUMN_WIDTH: AnyObject = {
  [COLUMN_NAMES.ref_number]: 245,
  [COLUMN_NAMES.payment_external_id]: 245,
  [COLUMN_NAMES.race_title]: 285,
  [COLUMN_NAMES.distance_original_price]: 150,
  [COLUMN_NAMES.discount]: 150,
  [COLUMN_NAMES.custom_fields_sum]: 150,
  [COLUMN_NAMES.total]: 150,
  [COLUMN_NAMES.distance_title]: 300,
};

export const GENDER_OPTIONS: AnyObject = [
  {
    key: 'gender:1',
    value: '1',
    label: 'Male',
  },
  {
    key: 'gender:2',
    value: '2',
    label: 'Female',
  },
  {
    key: 'gender:3',
    value: '3',
    label: 'Other',
  },
];

export const GENDER_LABELS: AnyObject = {
  '1': 'Male',
  '2': 'Female',
  '3': 'Other',
};

export const ORDER_ADMIN_STATUSES = [
  {
    key: 1,
    value: 'Incomplete',
    label: 'Incomplete',
  },
  {
    key: 2,
    value: 'Succeeded',
    label: 'Succeeded',
  },
  {
    key: 3,
    value: 'Canceled',
    label: 'Canceled',
  },
  {
    key: 4,
    value: 'Failed',
    label: 'Failed',
  },
  {
    key: 5,
    value: 'Partial refunded',
    label: 'Partial refunded',
  },
  {
    key: 6,
    value: 'Refunded',
    label: 'Refunded',
  },
];

export const ORDER_ORGANIZER_STATUSES = [
  {
    key: 2,
    value: 'Succeeded',
    label: 'Succeeded',
  },
  {
    key: 5,
    value: 'Partial refunded',
    label: 'Partial refunded',
  },
  {
    key: 6,
    value: 'Refunded',
    label: 'Refunded',
  },
];

export const START_LIST_ORDER_STATUSES = [
  {
    key: 1,
    value: 'Awaiting',
    label: 'Awaiting',
  },
  {
    key: 2,
    value: 'Ready',
    label: 'Ready',
  },
];

export const enum OrderStatus {
  pending = 1,
  paid = 2,
  failed = 3,
}

export const enum PaymentStatus {
  pending = 1,
  paid = 2,
  aborted = 3,
  failed = 4,
  partialRefunded = 5,
  refunded = 6,
  onSite = 7,
}

export const PaymentStatusLabels: AnyObject = {
  1: 'Pending',
  2: 'Paid',
  3: 'Aborted',
  4: 'Failed',
  5: 'Partial refunded',
  6: 'Refunded',
  7: 'On site',
};

export const enum OrderItemType {
  distance = 1,
  custom_field = 2,
  refund_protect = 3,
  transfer_registration = 5,
  service_fee = 6,
}
