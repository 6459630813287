import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { clearFieldError, getRelationErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';

import { DNDItem } from 'components/DragNDrop';
import { TextField } from 'components/Fields';
import { Icon } from 'components/Icon';

import { t } from 'utils';

import { progressStore } from 'stores';

import { DISTANCE } from '../../../../../../constants';

type Props = {
  onChange: (id: number | string, data: CustomFieldValue, callback?: Function) => void;
  onRemove: (id: number | string) => void;
  toggleCollapse: React.MouseEventHandler;
  value: CustomFieldValue;
  index: number;
  errorPrefix: string;
  currency: string;
  withoutDelete: boolean;
  open: boolean;
  isBlank: boolean;
  hasCompletedOrders: boolean;
};

const ITEM_ERROR_PREFIX = 'values';
const FIELDS = {
  price: 'price',
  vat_percents: 'vat_percents',
};

@observer
class CustomFieldValueItem extends React.Component<Props> {
  onlyNumberRegexp = /^[\d.]*$/;
  vatPercentsBackend = /custom_fields.\d+.values.\d+.vat_percents/;

  onChange = (name: string, changedValue: unknown, callback: Function = () => {}) => {
    const { value, onChange } = this.props;

    const changeData = {
      ...value,
      [name]: changedValue,
    };

    const id = value.id || value.__id || '';

    onChange(id, changeData);
    callback();
  };

  onChangeOnlyNumber = (e: React.ChangeEvent<HTMLInputElement>, nestedKey?: string) => {
    if (this.onlyNumberRegexp.test(e.currentTarget.value.toString())) {
      this.onChangePlain(e, nestedKey);
    }
  };

  onChangePlain = (e: React.ChangeEventHandler | any, nestedKey?: string) => {
    const { name, value } = e.currentTarget;

    const formattedValue = this._formatValue({ name, value });
    this.onChange(name, formattedValue, () => {});
  };

  defaultInputProps = (name: string) => {
    const value = (this.props.value as AnyObject)[name];

    return {
      value: value,
      name,
      errors: [],
      fullWidth: true,
    };
  };

  onMouseDown = (e: React.MouseEvent) => {
    const { toggleCollapse } = this.props;
    const { open } = this.props;

    if (open) {
      toggleCollapse(e);
    }
  };

  onRemove = () => {
    if (progressStore.isLoading(`UPDATE_${DISTANCE}`)) return;

    const { value, onRemove } = this.props;
    const id = value.id || value.__id || '';
    onRemove(id);
  };

  isError = (index: number): boolean => {
    // TODO, ready to use solution in case we need to add error markers for collapsed form
    // const errors = getRelationErrors(index, `${this.props.errorPrefix}${ITEM_ERROR_PREFIX}`);

    // if (!errors) {
    //   return false;
    // }

    // return values(flat(errors)).some(Boolean)

    return false;
  };

  _formatValue = ({ name, value }: { name: string; value: string }) => {
    switch (name) {
      case FIELDS.price:
        return !!value ? value : null;
      case FIELDS.vat_percents:
        return value || null;
      default:
        return value;
    }
  };

  render() {
    const { index, value, currency, open, toggleCollapse, withoutDelete, errorPrefix, isBlank, hasCompletedOrders } = this.props;
    const disabled = progressStore.isLoading(`UPDATE_${DISTANCE}`);

    if (value._delete) {
      return null;
    }

    const classesDnd = classNames('value-item', { error: this.isError(index) });

    const classesWrap = classNames('item-form', { hide: !open });

    const OpenIcon = open ? KeyboardArrowUp : KeyboardArrowDown;

    const headerOptions = {
      title: value?.value || t.staticAsString('distances.steps.customFieldsForm.formValueTitle'),
      className: 'value-header',
      controls: (
        <div className='item-controls'>
          {!withoutDelete && <Icon className='delete-icon' value='delete-active' onClick={this.onRemove} />}
          <OpenIcon className='open-icon' onClick={toggleCollapse} />
        </div>
      ),
    };

    return (
      <DNDItem
        className={classesDnd}
        id={`${value.id || value.__id || ''}`}
        index={value.index}
        onMouseDown={this.onMouseDown}
        headerOptions={headerOptions}
        isDragDisabled={disabled}
      >
        <div className={classesWrap}>
          <TextField
            {...this.defaultInputProps('value')}
            label={t.staticAsString('distances.steps.customFieldsForm.value')}
            errors={getRelationErrors(index, `${errorPrefix}.${ITEM_ERROR_PREFIX}`, 'value')}
            onChange={(e: React.ChangeEvent) => this.onChangePlain(e)}
            disabled={progressStore.isLoading(`UPDATE_${DISTANCE}`)}
          />
          <div className='number-input-wrap'>
            <TextField
              {...this.defaultInputProps('price')}
              className='form-control number-input'
              label={t.staticAsString('distances.steps.customFieldsForm.price', { currency }) as any}
              errors={getRelationErrors(index, `${errorPrefix}.${ITEM_ERROR_PREFIX}`, 'price')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeOnlyNumber(e)}
              disabled={progressStore.isLoading(`UPDATE_${DISTANCE}`)}
            />
            <TextField
              {...this.defaultInputProps('vat_percents')}
              className='form-control number-input'
              label={t.staticAsString('distances.steps.customFieldsForm.vat_percents')}
              errors={getRelationErrors(index, `${errorPrefix}.${ITEM_ERROR_PREFIX}`, 'vat_percents')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeOnlyNumber(e)}
              disabled={progressStore.isLoading(`UPDATE_${DISTANCE}`)}
            />
          </div>
        </div>
      </DNDItem>
    );
  }
}

export { CustomFieldValueItem };
