import { omit, reduce, sortBy } from 'lodash';
import { stateRelations } from 'modules/Distances/shared/stateHelpers';
import { formatData } from 'modules/Distances/utils/formatDataToBE';

import { helperRacesStore } from 'stores';

function formatFieldData(data: AnyObject): AnyObject {
  return reduce(
    data,
    (result, value, fieldName) => {
      const race = helperRacesStore.selected;
      const defaultLocale = race?.pref_lang_code || 'en';
      switch (fieldName) {
        case 'name':
        case 'description':
          return {
            ...result,
            [defaultLocale]: {
              ...result[defaultLocale],
              [fieldName]: value,
            },
          };
        case 'race_qty':
        case 'race_length':
          return {
            ...result,
            [fieldName]: value ? Number(value) : null,
          };

        case 'email_content.content':
          return {
            ...result,
            email_content: {
              ...result.email_content,
              [defaultLocale]: {
                ...result.email_content?.[defaultLocale],
                content: value,
              },
            },
          };
        case 'registrationFields':
          return {
            ...result,
            registration_fields: value.map((item) => ({
              ...omit(item, ['distance']),
              selected: Number(item.selected),
            })),
          };

        case 'checkpoints':
        case 'waves':
          if (!value.length) return { ...result, [fieldName]: [] };
          const formattedDataToBe = formatData(fieldName, data);
          return {
            ...result,
            [fieldName]: sortBy(formattedDataToBe, (item) => Boolean(item._delete)).map((item) => ({
              [defaultLocale]: { name: item.name },
              ...omit(item, ['name']),
            })),
          };
        case 'classes':
        case 'disciplines':
          if (!value.length) return { ...result, [fieldName]: [] };
          const formattedToBeData = formatData(fieldName, data);
          return {
            ...result,
            [fieldName]: sortBy(formattedToBeData, (item) => Boolean(item._delete)).map((item) => ({
              [defaultLocale]: { title: item.title },
              ...omit(item, ['title']),
            })),
          };

        case 'medical_assistants':
        case 'prices':
          return { ...result, [fieldName]: formatData(fieldName, data) };

        case 'editorSettings':
          return { ...result, [stateRelations.editorSettings]: value };

        case 'allow_no_profile_registration':
          return { ...result, [fieldName]: Boolean(value) };

        default:
          return { ...result, [fieldName]: value };
      }
    },
    {} as AnyObject,
  );
}

export { formatFieldData };
