export const BASE_URL = '/api/v1/admin';
export const BASE_V2_ADMIN_URL = '/api/v2/admin';
export const BASE_WEB_URL = '/api/v1/web';

export const MISSING_PAYMENT_DETAILS = `${BASE_URL}/organizers/:organizerID/missing-payment-details`;

// Dashboard constants
export const AGE_DATA_URL = `${BASE_URL}/charts/age`;
export const REGISTRATIONS_DATA_URL = `${BASE_URL}/charts/registrations`;
export const TURNOVER_DATA_URL = `${BASE_URL}/charts/turnover`;
export const PARTICIPANTS_DATA_URL = `${BASE_URL}/charts/participants`;
export const COUPONS_DATA_URL = `${BASE_URL}/charts/coupons`;
export const GENDER_DATA_URL = `${BASE_URL}/charts/gender`;
export const COUNTRIES_DATA_URL = `${BASE_URL}/charts/nationality`;

export const SESSIONS_URL = `${BASE_URL}/tokens`;
export const REGISTRATION_URL = `${BASE_URL}/organizers/onboarding`;
export const FORGOT_PASSWORD_URL = `${BASE_URL}/passwords/forgot`;
export const SPORT_TYPES_URL = `${BASE_URL}/sports`;
export const RACES_URL = `${BASE_URL}/races`;
export const RACE_CLASSES_URL = `${BASE_URL}/races/:id/classes`;
export const RACE_CUSTOM_FIELDS_URL = `${BASE_URL}/races/:id/custom-fields`;
export const RACE_WAVES_URL = `${BASE_URL}/races/:id/waves`;
export const DISTANCE_WAVES_URL = `${BASE_URL}/distances/:id/waves`;
export const REPORTS_URL = `${BASE_URL}/reports`;
export const REPORTS_BY_ORGANIZER = `${BASE_URL}/organizers/:id/reports`;
export const REPORT_URL = `${BASE_URL}/reports/:reportId`;
export const TRANSACTIONS_URL = `${BASE_URL}/payment-transactions`;
export const TRANSACTION_URL = `${BASE_URL}/payment-transactions/:transactionId`;
export const COMMISSIONS_URL = `${BASE_URL}/commissions`;

export const DEFAULT_COMMISSIONS_URL = `${BASE_URL}/default-commissions`;
export const COMMISSION_URL = `${BASE_URL}/commissions/:id`;
export const ORGANIZER_PAYMENT_METHODS = `${BASE_URL}/organizer-payment-methods`;
export const ORGANIZER_PAYMENT_METHOD = `${BASE_URL}/organizer-payment-methods/:id`;

export const COMMISSION_BY_ORGANIZER = `${BASE_URL}/organizers/:id/race-commissions`;
export const RECEIPT_SETTINGS_URL = `${BASE_URL}/races/:id/receipt-settings`;
export const EDITOR_SETTINGS_URL = `${BASE_URL}/races/:id/editor-settings`;
export const RACE_URL = `${BASE_URL}/races/:id`;
export const RACE_IMAGE_URL = `${BASE_URL}/races/:id/images`;
export const RACE_CONTACT_EMAILS_URL = `${BASE_URL}/races/:id/contact-emails`;
export const RACE_CONTACT_EMAIL_URL = `${BASE_URL}/races/:id/contact-emails/:emailId`;
export const RACE_PARTICIPANTS_COUNT = `${BASE_URL}/races/:id/participants-count`;
export const DISTANCES_URL = `${BASE_URL}/distances`;

export const DISTANCE_URL = `${BASE_URL}/distances/:distanceId`;

export const USERS_URL = `${BASE_URL}/users`;
export const USER_URL = `${BASE_URL}/users/:id`;
export const RACE_DISTANCES_URL = `${BASE_URL}/races/:raceId/distances`;
export const RACE_DISTANCE_URL = `${BASE_URL}/distances/:id`; //the same as DISTANCE_URL
export const RACE_DISTANCE_DND_URL = `${BASE_URL}/distances/:id/position`;
export const IMAGE_UPLOAD_URL = `${BASE_URL}/uploads/image`;
export const COUPONS_URL = `${BASE_URL}/coupons`;
export const COUPON_URL = `${BASE_URL}/coupons/:id`;
export const COUPON_ORDERS_URL = `${BASE_URL}/coupons/:id/orders`;
export const COUNTRIES_URL = `${BASE_WEB_URL}/countries`;
export const ORGANIZERS_URL = `${BASE_URL}/organizers`;
export const ORGANIZERS_UTILS_URL = `${BASE_URL}/utils/organizers`;
export const ORGANIZER_URL = `${BASE_URL}/organizers/:id`;
export const ORGANIZER_EXPORT_URL = `${BASE_URL}/organizer/export`;
export const ORGANIZER_PAYMENT_EXPORT_URL = `${BASE_URL}/organizers/:organizerId/payment/:id`;
export const UPDATE_SYSTEM_USER_CURRENCY_URL = `${BASE_URL}/organizers/:id/currencies/:currencyId`;
export const CREATE_SYSTEM_USER_CURRENCY_URL = `${BASE_URL}/organizers/:id/currencies`;
export const DELETE_SYSTEM_USER_CURRENCY_URL = `${BASE_URL}/organizers/:id/currencies/:currencyId`;
export const SYSTEM_USERS_URL = `${BASE_URL}/organizers`;
export const SYSTEM_USER_URL = `${BASE_URL}/organizers/:id`;
export const SYSTEM_USER_EXPORT_URL = `${BASE_URL}/organizer/export`;
export const SYSTEM_USER_PAYMENT_EXPORT_URL = `${BASE_URL}/organizers/:organizerId/payment/:id`;
export const SYSTEM_USER_ROLES_URL = `${BASE_URL}/roles`;
export const CUSTOMERS_URL = `${BASE_URL}/users`;
export const CUSTOMER_URL = `${BASE_URL}/users/:id`;
export const CUSTOMER_EXPORT_URL = `${BASE_URL}/customer/export`;
export const PAYMENT_URL = `${BASE_URL}/orders/:id`;
export const PAYMENTS_URL = `${BASE_URL}/orders`;
export const PAYMENTS_EXPORT_URL = `${BASE_URL}/payment/export`;
export const PAYMENT_EXPORT_URL = `${BASE_URL}/organizers/:organizerId/payment/:id`;
export const PAYMENT_TRANSACTIONS = `${BASE_URL}/payments/:id/transactions`;
export const ORDER_PAYMENT = `${BASE_URL}/orders/:id/payment`;
export const EXPORT_PAYMENT_RECEIPT = `${BASE_URL}/payments/:id/receipt`;
export const EXPORT_URL = `${BASE_URL}/exports/:id`;
export const ORGANIZER_PAYMENTS_URL = `${BASE_URL}/organizers/:id/payments`;
export const PROFILE_URL = `${BASE_URL}/profile`;
export const RACER_PROFILE_URL = `${BASE_URL}/racers/profile`;
export const RACERS_URL = `${BASE_URL}/racers`;
export const RACERS_APPROVE_MANUAL_PAYMENT_URL = `${BASE_URL}/racers/:id/approve-manual-payment`;
export const RACERS_REMOVE_CF_URL = `${BASE_URL}/racers/:id/custom-field/:cfid`;
export const ORGANIZER_RACERS_URL = `${BASE_URL}/organizer/racers`;
export const ORGANIZER_RACES_URL = `${BASE_URL}/organizer/races`;
export const ORGANIZER_DISTANCES_URL = `${BASE_URL}/distances`; //the same as DISTANCES_URL
export const ORGANIZER_RACERS_EXPORT_URL = `${BASE_URL}/organizer/racers/export`;
export const DISTANCE_RACERS_URL = `${BASE_URL}/distances/:distanceId/racers`;
export const DISTANCE_COMPLETED_ORDERS_URL = `${BASE_URL}/distances/:distanceId/has-completed-orders`;
export const CURRENCIES_URL = `${BASE_URL}/currencies`;
export const DUPLICATE_RACE_URL = `${BASE_V2_ADMIN_URL}/races/:id/duplicate`;
export const DISTANCE_SQUADS_URL = `${BASE_URL}/distances/:id/squads`;
export const DISTANCE_RESULTS_URL = `${BASE_V2_ADMIN_URL}/distances/:id/results`;
export const DISTANCE_RESULT_URL = `${BASE_URL}/results/:id`;
export const DISTANCE_RACER_URL = `${BASE_URL}/racers/:id`;
export const DISTANCE_TEAM_URL = `${BASE_V2_ADMIN_URL}/teams/:id`;
export const DISTANCE_FINISH_URL = `${BASE_URL}/distances/:id/finish`;
export const TEAMS_URL = `${BASE_URL}/teams`;
export const DISTANCE_TEAMS_URL = `${BASE_URL}/distances/:id/teams`;
export const TRANSFER_REGISTRATION_URL = `${BASE_URL}/distances/:id/transfer-registration-settings`;
export const EDIT_REGISTRATION_URL = `${BASE_URL}/distances/:id/edit-information-settings`;
export const RACER_RESULT_URL = `${BASE_URL}/racers/:racerId/results`;
export const TEAM_RESULT_URL = `${BASE_URL}/teams/:teamId/results`;
export const RACER_SPLIT_URL = `${BASE_V2_ADMIN_URL}/racers/:racerId/splits/:checkpointId`;
export const TEAM_SPLIT_URL = `${BASE_V2_ADMIN_URL}/teams/:teamId/splits/:checkpointId`;
export const DISTANCE_RESULTS_EXPORT_URL = `${BASE_URL}/distances/:distanceId/results/legacy-export`;
export const DISTANCE_RESULTS_TEMPLATE_EXPORT_URL = `${BASE_URL}/distances/:distanceId/results/template`;
export const DISTANCE_RESULTS_IMPORT_URL = `${BASE_URL}/distances/:distanceId/results/template`;
export const DISTANCE_SQUAD_UPDATE_URL = `${BASE_URL}/distances/:distanceId/squads/:squadId`;
export const DUPLICATE_DISTANCE_URL = `${BASE_V2_ADMIN_URL}/distances/:distanceId/duplicate`;
export const RESULTS_URL = `${BASE_URL}/distances/:distanceId/results`;
export const RACERS_RESULT_URL = `${BASE_V2_ADMIN_URL}/racers/:id/results`;
export const TEAMS_RESULT_URL = `${BASE_V2_ADMIN_URL}/teams/:id/results`;
export const START_LIST_URL = `${BASE_URL}/distances/:distanceId/start-list`;
export const DISTANCE_HIDE = `${BASE_URL}/distances/:distanceId`; //the same as DISTANCE_URL
export const REFUND_DELETE_URL = `${BASE_URL}/refund-protect/:id`;
export const IS_CUSTOM_FIELD_HAS_RACER = `${BASE_URL}/distances/:distanceId/custom-fields/:customFieldId}/has-racers`;
// status change urls
export const PUBLISH_RACE_URL = `${BASE_URL}/races/:id/publish`;
export const UNPUBLISH_RACE_URL = `${BASE_URL}/races/:id/unpublish`;
export const APPROVE_RACE_URL = `${BASE_URL}/races/:id/approve`;
export const REJECT_RACE_URL = `${BASE_URL}/races/:id/reject`;
export const AWAITING_APPROVE_RACE_URL = `${BASE_URL}/races/:id/awaiting`;
// NEW Export API
export const EXPORT_JOB_ID = `${BASE_URL}/jobs/:id`;
export const EXPORT_REPORTS_URL = `${BASE_URL}/exports/reconciliation-reports`;
export const EXPORT_PAYMENTS_URL = `${BASE_URL}/exports/payment-transactions`;
export const SERVICE_FEE_REPORT_URL = `${BASE_URL}/exports/service-fee`;

export const EXPORT_COUPONS_URL = `${BASE_URL}/exports/coupons`;
export const EXPORT_COUPON_URL = `${BASE_URL}/exports/coupons/:id/details-export`;
export const EXPORT_RACERS_URL = `${BASE_URL}/exports/racers`;
export const EXPORT_CUSTOMERS_URL = `${BASE_URL}/exports/users`;
export const EXPORT_ORGANIZERS_URL = `${BASE_URL}/exports/organizers`;
export const EXPORT_ORGANIZER_PAYMENT_URL = `${BASE_URL}/exports/organizers/:organizerId/payments/:paymentId`;
export const EXPORT_RESULTS_URL = `${BASE_URL}/exports/distances/:id/results`;
export const EXPORT_TEMPLATE_URL = `${BASE_URL}/distances/:distanceId/results/template`;
export const EXPORT_START_LIST_URL = `${BASE_URL}/exports/distances/:id/start-list`;
export const EXPORT_IMPORT_START_LIST_TEMPLATE_URL = `${BASE_URL}/distances/:distanceId/startlist-template`;
export const EXPORT_TEAM_START_LIST_URL = `${BASE_URL}/exports/distances/:id/team-start-list`;
export const EXPORT_RACE_SUMMARY_URL = `${BASE_URL}/exports/races/:id/start-list`;
export const EXPORT_START_LIST_WAVES_URL = `${BASE_URL}/exports/distances/:id/waves`;
export const EXPORT_ORDER = `${BASE_URL}/exports/orders/:id`;
export const EXPORT_SMART_LINKS = `${BASE_URL}/exports/magic-links`;
export const EXPORT_SMART_LINK = `${BASE_URL}/exports/magic-links/:token/details-export `;

export const EXPORT_CAMPAIGN_LINK = `${BASE_V2_ADMIN_URL}/exports/campaign-links/:token/details-export`;
export const EXPORT_CAMPAIGN_LINKS = `${BASE_V2_ADMIN_URL}/exports/campaign-links`;

// migrating to new delayed(background loading) API
// export const PAYMENTS_EXPORT_URL = `${BASE_URL}/payment/export`;
// export const PAYMENT_EXPORT_URL = `${BASE_URL}/payment/export/:id`;
// app health check
export const API_HEALTH_CHECK = '/api/health';
// export const TRANSLATIONS_URL = `${BASE_URL}/translation/index`;
export const TRANSLATIONS_URL = {
  development: 'https://d1cckfr8cgrocc.cloudfront.net/translations/admin/',
  production: 'https://d3q0esj9j6r0nh.cloudfront.net/translations/admin/',
  staging: 'https://d1mloj4u9jso1k.cloudfront.net/translations/admin/',
};

export const AVAILABLE_LOCALES_URL = `${BASE_URL}/locales`;

// Field translations
export const SUPER_TRANSLATIONS_CONFIRM = `${BASE_URL}/super-translations/confirm`;
export const FIELD_SUPER_TRANSLATIONS_URL = `${BASE_URL}/super-translations`;

export const FIELD_RACE_TRANSLATIONS_URL = `${BASE_URL}/races/:id/translations`;
export const FIELD_RACE_TRANSLATION_URL = `${BASE_URL}/races/:id/translations/:tid`;

export const FIELD_DISTANCE_TRANSLATIONS_URL = `${BASE_URL}/distances/:id/translations`;
export const FIELD_DISTANCE_TRANSLATION_URL = `${BASE_URL}/distances/:id/translations/:tid`;

export const FIELD_WAVES_TRANSLATIONS_URL = `${BASE_URL}/waves/:id/translations`;
export const FIELD_WAVES_TRANSLATION_URL = `${BASE_URL}/waves/:id/translations/:tid`;

export const FIELD_CLASSES_TRANSLATIONS_URL = `${BASE_URL}/classes/:id/translations`;
export const FIELD_CLASSES_TRANSLATION_URL = `${BASE_URL}/classes/:id/translations/:tid`;

export const FIELD_DISCIPLINES_TRANSLATIONS_URL = `${BASE_URL}/disciplines/:id/translations`;
export const FIELD_DISCIPLINES_TRANSLATION_URL = `${BASE_URL}/disciplines/:id/translations/:tid`;

export const FIELD_CHECKPOINTS_TRANSLATIONS_URL = `${BASE_URL}/checkpoints/:id/translations`;
export const FIELD_CHECKPOINTS_TRANSLATION_URL = `${BASE_URL}/checkpoints/:id/translations/:tid`;

export const FIELD_CUSTOM_FIELDS_TRANSLATIONS_URL = `${BASE_URL}/custom-fields/:id/translations`;
export const FIELD_CUSTOM_FIELDS_TRANSLATION_URL = `${BASE_URL}/custom-fields/:id/translations/:tid`;

export const FIELD_CUSTOM_FIELDS_VALUE_TRANSLATIONS_URL = `${BASE_URL}/custom-field-values/:id/translations`;
export const FIELD_CUSTOM_FIELDS_VALUE_TRANSLATION_URL = `${BASE_URL}/custom-field-values/:id/translations/:tid`;

// Google translation temporary server
// Integrations
export const RACE_INTEGRATIONS = `${BASE_URL}/races/:id/integrations`;
export const RACE_INTEGRATION = `${BASE_URL}/races/:raceId/integrations/:id`;

export const GOOGLE_TRANSLATE_URL = `${BASE_URL}/translations/google`;
// Upload files
export const FILES_URL = `${BASE_WEB_URL}/files`;

//utils
export const GET_HOSTS_URL = `${BASE_URL}/utils/hosts`;
export const TASKS_URL = `${BASE_URL}/utils/tasks`;
export const COPY_RACERS_URL = `${BASE_URL}/utils/copy-racers`;
export const COPY_RACERS_DELETE_TASK_URL = `${BASE_URL}/utils/copy-racers/:id`;
export const MERGE_REQUEST_URL = `${BASE_URL}/utils/merge-accounts`;
export const REFUND_URL = `${BASE_URL}/utils/refund`;
export const GENERATE_COUPONS = `${BASE_URL}/utils/generate-coupons`;
export const GENERATE_BIB_SERIES = `${BASE_URL}/utils/generate-bib-series`;
export const RESEND_VERIFICATION_LINK = `${BASE_URL}/organizers/verification/resend`;
export const ORGANIZER_VERIFICATION = `${BASE_URL}/organizers/verification`;

// GPX
export const GPX_ROUTE_URL = `${BASE_URL}/distances/:distanceId/route`;
export const GPX_SAVE_ROUTE_URL = `${BASE_URL}/distances/:distanceId/route`;
export const SAVE_CHECK_POINT_URL = `${BASE_URL}/checkpoints/:checkpointId/coordinates`;

// Synchronizations

export const SYNCHRONIZATIONS_URL = `${BASE_URL}/synchronizations`;
export const SYNCHRONIZATION_URL = `${BASE_URL}/synchronizations/:id`;
export const ENABLE_SYNCHRONIZATION_URL = `${BASE_URL}/synchronizations/:id/enable`;
export const DISABLE_SYNCHRONIZATION_URL = `${BASE_URL}/synchronizations/:id/disable`;
export const PUBLISH_SYNCHRONIZATION_URL = `${BASE_URL}/synchronizations/:id/publish`;
export const MAPPING_SYNCHRONIZATION_URL = `${BASE_URL}/synchronizations/:id/mappings`;

export const API_DISTANCES_SYNC = `${BASE_URL}/synchronizations/:id/distances`;
export const API_CLASSES_SYNC = `${BASE_URL}/synchronizations/:id/classes`;
export const API_WAVES_SYNC = `${BASE_URL}/synchronizations/:id/waves`;
export const API_CUSTOM_FIELDS_SYNC = `${BASE_URL}/synchronizations/:id/custom-fields`;

// Magic Links
export const MAGIC_LINKS_URL = `${BASE_URL}/magic-links`;
export const MAGIC_LINK_URL = `${BASE_URL}/magic-links/:token`;
export const MAGIC_LINK_ORDERS_URL = `${BASE_URL}/magic-links/:token/orders`;

// Campaign Links
export const CAMPAIGN_LINKS_URL = BASE_V2_ADMIN_URL + '/campaign-links';
export const CAMPAIGN_LINK_URL = BASE_V2_ADMIN_URL + '/campaign-links/:token';
export const CAMPAIGN_LINK_ORDERS_URL = BASE_V2_ADMIN_URL + '/campaign-links/:token/orders';

// Service fee
