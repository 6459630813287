import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, IconButton, TableCell, TableRow } from '@mui/material';
import classNames from 'classnames';
import { isEmpty, intersection, has } from 'lodash';
import { Observer } from 'mobx-react';
import * as React from 'react';

import { CONFIRM_POPUP_TYPES, ORDER_REGISTRATION_FIELDS, RACER, START_LIST } from 'src/constants';

import { oneFieldValidation } from 'utils';
import { t } from 'utils';

import { confirmationModalStore, errorsStore, progressStore, toastStore } from 'stores';

import { validateRegistrationState } from '../../../utils';
import { racerStatus } from '../../../utils';

import racersService from '../../../services/racersService';

import { Racers as RacersStore } from '../../../stores';

import { PaymentStatus, PaymentStatusLabels } from '../../../../Payments/constants';
import { racerFields } from '../../../Filters';
import { racerEditValidation } from '../../../validations';
import { Controls } from '../../Controls';
import { CustomFields } from '../../Fields/CustomFields';
import { PersonalFields } from '../../Fields/PersonalFields';
import { RegisteredFields } from '../../Fields/RegisteredFields';
import { RenderPaymentStatus } from '../../Shared/RenderPaymentStatus';
import { Prices } from './Prices';
import { MAIN_COLUMNS } from './THead/columns';

type Props = {
  item: RacerType;
  distance: DistanceType;
  startlistRacersStore: RacersStore;
  checkedRequiredFields: AnyObject;
  deleteRacer: (racerId: number) => void;
  handleCopyLink: (racerId: number) => Promise<unknown>;
  handleResend: (racerId: number, isSuccesfulEmail: boolean) => void;
  handleAttachToProfile: (racer: RacerType) => void;
  handleDeactivateRefundProtect: (racerId: number) => void;
  hasPrice: boolean;
  errors?: Array<AnyObject>;
};

type State = {
  isEdit: string | null;
  isEditField: AnyObject | null;
  racer: RacerType | AnyObject;
  changedFields: AnyObject;
};

const WAVE_TYPE = 'wave';
const action = `UPDATE_${START_LIST}`;
const VALIDATE_FIELDS: AnyObject = {
  bib_number: /^[0-9]+$/,
};
class Item extends React.Component<Props, State> {
  static defaultProps = {
    startlistRacersStore: null as any,
  };

  state: State = {
    isEdit: null,
    isEditField: null,
    racer: {},
    changedFields: {},
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { isEdit } = this.state;

    if (isEdit && !prevState.isEdit) {
      document.addEventListener('keyup', this.handlePressEnter as AnyFunction);
    }
  }

  handlePressEnter = (e: React.KeyboardEvent) => {
    const { isEdit, isEditField } = this.state;
    const { distance } = this.props;

    if (e.keyCode === 13) {
      const isCustomField = (distance.custom_fields || []).some((item) => item.id === isEditField?.id);

      isCustomField ? this.saveCustomField() : this.saveRacer(String(isEdit));
    }
  };

  onChange = (name: string, value: any, changedValue: any, callback: Function = () => {}): void => {
    if (VALIDATE_FIELDS[name] && value.length && !value.match(VALIDATE_FIELDS[name])) {
      return;
    }

    this.setState(
      {
        ...this.state,
        racer: {
          ...this.state.racer,
          [name]: value,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: changedValue,
        },
      },
      () => {
        const constrainsField = { [name]: racerEditValidation[name] };

        oneFieldValidation(action, this.state.racer, constrainsField);
      },
    );

    callback && callback();
  };

  getRequiredFields = (): Array<React.ReactNode> => {
    const { distance, item } = this.props;
    const { racer, isEdit, changedFields } = this.state;

    return this._additionalFields().map((field) => (
      <TableCell
        className={classNames('cell')}
        key={`${field}${item.id}`}
        onDoubleClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          this.editRacer(field);
        }}
      >
        <RegisteredFields
          field={field}
          item={(isEdit ? racer : item) as any}
          isEdit={isEdit}
          onChange={this.onChange}
          changedFields={changedFields}
          saveRacer={this.saveRacer}
          distance={distance}
        />
      </TableCell>
    ));
  };

  showCustomFields = (): any => {
    const { distance, item } = this.props;
    const { racer, isEdit, changedFields } = this.state;
    const currentRacer: AnyObject = isEdit ? racer : item;

    const handleConfirm = (cf: RacerCustomFieldValue) => {
      confirmationModalStore.openModal({
        title: 'Remove field',
        body: 'This custom field will be removed from the racer. Are you sure?',
        type: CONFIRM_POPUP_TYPES.confirm,
        onConfirm: () => racersService.deleteCustomField(cf, currentRacer as RacerType),
      });
    };

    return (distance.custom_fields || []).map((field) => {
      const racersField = ((currentRacer && currentRacer.fields) || []).filter((itemField: AnyObject) => itemField.field_id === field.id);
      const isEmpty = racersField.length < 1 || (field.type === 'textfield' && racersField[0].value === '');
      return (
        <TableCell
          className={classNames('cell')}
          key={`${field.name}${item.id}`}
          onDoubleClick={(e: React.SyntheticEvent) => {
            e.stopPropagation();
            this.editRacer(field.name, field);
          }}
        >
          <CustomFields
            item={currentRacer as any}
            isEdit={isEdit}
            distancesField={field}
            distance={distance}
            onChange={this.onChange}
            changedFields={changedFields}
            saveChanges={this.saveCustomField}
          />
          {!isEmpty && (
            <IconButton onClick={() => handleConfirm(field)}>
              <DeleteIcon style={{ color: 'lightgray' }} />
            </IconButton>
          )}
        </TableCell>
      );
    });
  };

  renderRefundProtect = (): any => {
    const { distance, item } = this.props;
    const { racer, isEdit } = this.state;
    const currentRacer: AnyObject = isEdit ? racer : item;

    if (!distance.refund_protect_enabled) {
      return null;
    }

    const renderMessage = () => {
      if (currentRacer.order_refund_protect_amount) {
        return `${t.staticAsString('racers.list.yes')} (${+(currentRacer.order_refund_protect_amount / 100).toFixed(2)} ${
          currentRacer.order_currency
        })`;
      }

      return t.staticAsString('racers.list.no');
    };

    return (
      <TableCell className={classNames('cell')} key={`${item.id}`}>
        {renderMessage()}
      </TableCell>
    );
  };

  renderPersonalInfo = (fields: Array<any>): React.ReactNode => {
    const { item, distance, errors } = this.props;
    const { isEdit, racer, changedFields } = this.state;

    const fieldsToRender = fields.filter((field) => this._isFieldShown(field));
    return fieldsToRender.map((field) => (
      <TableCell
        className={classNames('cell')}
        key={`${field}-${item.id}`}
        onDoubleClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          this.editRacer(field);
        }}
      >
        <PersonalFields
          field={field}
          item={(isEdit ? racer : item) as any}
          isEdit={isEdit}
          onChange={this.onChange}
          distance={distance}
          saveRacer={this.saveRacer}
          errors={errors || []}
        />
      </TableCell>
    ));
  };

  editRacer = (field: string, fieldObject: AnyObject | null = null) => {
    const { item } = this.props;
    const { isEdit } = this.state;

    const isAwaiting = racerStatus.isAwaiting(item);
    const isBibNumberField = field === MAIN_COLUMNS[0];

    if (isEdit === field || (isAwaiting && isBibNumberField)) {
      return;
    }

    this.setState({
      isEdit: field,
      isEditField: fieldObject,
      racer: this.props.item,
      changedFields: {},
    });
  };

  cancelEdit = () => {
    this.setState({
      isEdit: null,
      isEditField: null,
      racer: {},
      changedFields: {},
    });
  };

  saveRacer = async (field: string) => {
    const { racer, changedFields } = this.state;

    if (!field) {
      return this.cancelEdit();
    }

    const constrainsField = { [field]: racerEditValidation[field] };

    if (has(this.state.changedFields, 'bib_number')) {
      const [shouldProceed, shouldCancel] = validateRegistrationState(
        racer as RacerType,
        this.props.item.bib_number,
        this.state.changedFields.bib_number,
      );

      if (shouldCancel) {
        return this.cancelEdit();
      }

      if (!shouldProceed) {
        return;
      }
    }

    const isValidate = oneFieldValidation(action, this.state.changedFields, constrainsField);

    if (!isValidate || isEmpty(changedFields) || !racer.id) {
      return this.cancelEdit();
    }

    const status = await racersService.updateResource(this.getChangedFields(), racer as any);

    if (status) {
      document.removeEventListener('keyup', this.handlePressEnter as AnyFunction);
      this.cancelEdit();
    }

    errorsStore.clearFrontendError(action);
  };

  saveCustomField = async () => {
    const { racer, changedFields } = this.state;

    if (isEmpty(changedFields) || !racer.id) {
      return this.cancelEdit();
    }

    const changedCustomField = changedFields.fields[0];

    const status = await racersService.updateCustomField(changedCustomField, racer as any);

    if (status) {
      document.removeEventListener('keyup', this.handlePressEnter as AnyFunction);
      this.cancelEdit();
    }
  };

  priceColumns = (item: RacerType) => {
    const { hasPrice } = this.props;
    if (!hasPrice) {
      return null;
    }

    return <Prices value={item} />;
  };

  isSuccesfulEmail = (racer: RacerType) => {
    if (racer.payment_required) {
      return false;
    }
    if (racer.is_group_leader || racer.is_group_leader === null) {
      return true;
    }
    if (racer.order_id !== null && racer.status === 'paid') {
      return true;
    }
    return false;
  };

  _additionalFields = () => {
    const { checkedRequiredFields } = this.props;
    const registrationFields: Array<string> = Object.keys(checkedRequiredFields).filter((filter) => !!checkedRequiredFields[filter]);

    return intersection<string>(ORDER_REGISTRATION_FIELDS, registrationFields);
  };

  _isFieldShown = (field: string) => {
    const { distance, checkedRequiredFields } = this.props;
    const hasPrices = (distance.prices || []).length > 0;

    switch (field) {
      case racerFields['wave_id']:
        return distance.start_type === WAVE_TYPE;
      case racerFields['class_id']:
        return !!distance.classes?.length;
      case racerFields['discipline_id']:
        return !!distance.disciplines?.length;
      case racerFields['country_id']:
        return !!checkedRequiredFields['country_id'];
      case racerFields['nationality_id']:
        return !!checkedRequiredFields['nationality_id'];
      case racerFields['external_swimrun_id']:
        return !!checkedRequiredFields['external_swimrun_id'];
      case racerFields['city']:
        return !!checkedRequiredFields['city'];
      case racerFields['comment']:
        return true;
      case racerFields['order_coupon_codes']:
      case racerFields['order_id']:
      case racerFields['order_created_at']:
      case racerFields['order_total']:
      case racerFields['order_status']:
        return !!hasPrices;
      default:
        return true;
    }
  };

  getChangedFields = (): any => {
    const fields = this.state.changedFields;
    return has(fields, 'bib_number') ? { bib_number: parseInt(fields.bib_number) } : fields;
  };

  render() {
    const { item, handleCopyLink, deleteRacer, handleResend, handleDeactivateRefundProtect, handleAttachToProfile } = this.props;
    const isAwaiting = racerStatus.isAwaiting(item);

    return (
      <TableRow
        className={classNames({ awaiting: isAwaiting || item.payment_status === PaymentStatus.onSite })}
        key={`racerId${item.id}`}
        id={item.id as any}
      >
        <TableCell className={classNames('cell')}>
          <Controls
            isSuccesfulEmail={this.isSuccesfulEmail(item)}
            isAwaiting={isAwaiting}
            isRefundProtectPaid={!!item.order_refund_protect_amount}
            isAttachToProfile
            teamStatus={String(item.team_status)}
            handleCopy={() => handleCopyLink(item.id)}
            handleResend={() => handleResend(item.id, this.isSuccesfulEmail(item))}
            handleAttachToProfile={() => handleAttachToProfile(item)}
            handleDelete={() => deleteRacer(item.id)}
            handleDeactivateRefundProtect={() => handleDeactivateRefundProtect(item.id)}
          />
        </TableCell>
        {this.renderPersonalInfo(MAIN_COLUMNS)}
        {this.getRequiredFields()}
        {this.showCustomFields()}
        {this.renderRefundProtect()}
        {this.renderPersonalInfo([racerFields['external_swimrun_id']])}
        {this.renderPersonalInfo([racerFields['added_at']])}
        {this.renderPersonalInfo([racerFields['added_by']])}
        {this.priceColumns(item)}
        {this.renderPersonalInfo([racerFields['comment']])}
        <RenderPaymentStatus
          item={item}
          onConfirm={() => racersService.togglePaymentStatus({ payment_status: PaymentStatus.paid } as any, item)}
        />
      </TableRow>
    );
  }
}

export { Item };
