import { state } from 'modules/StartList/Filters/shared';

import { defaultListFilters } from 'utils';

import { FilterDescriptorWrapper } from 'models';

import { classId } from './classId';
import { countryId } from './countryId';
import { disciplineId } from './disciplineId';
import { gender } from './gender';
import { nationalityId } from './nationalityId';
import { waveId } from './waveId';

// Members Startist filters

type Cols =
  | 'comment'
  | 'external_swimrun_id'
  | 'added_at'
  | 'added_by'
  | 'bib_number'
  | 'team.name'
  | 'firstname'
  | 'lastname'
  | 'email'
  | 'gender'
  | 'country'
  | 'city'
  | 'wave_id'
  | 'class_id'
  | 'discipline_id'
  | 'country_id'
  | 'nationality_id'
  | 'address'
  | 'post_code'
  | 'company'
  | 'union'
  | 'payment_status'
  | 'birthday'
  | 'telephone'
  | 'shoe_size'
  | 'shirt_size'
  | 'emergency_name'
  | 'emergency_phone'
  | 'order_id'
  | 'order_total'
  | 'order_refund_protect_amount'
  | 'order_coupon_codes'
  | 'order_created_at'
  | 'order_status';

export const memberFields: {
  [K in Cols]: Cols;
} = {
  comment: 'comment',
  external_swimrun_id: 'external_swimrun_id',
  added_at: 'added_at',
  added_by: 'added_by',
  bib_number: 'bib_number',
  'team.name': 'team.name',
  firstname: 'firstname',
  lastname: 'lastname',
  email: 'email',
  gender: 'gender',
  address: 'address',
  country: 'country',
  city: 'city',
  wave_id: 'wave_id',
  class_id: 'class_id',
  discipline_id: 'discipline_id',
  country_id: 'country_id',
  nationality_id: 'nationality_id',
  post_code: 'post_code',
  company: 'company',
  union: 'union',
  birthday: 'birthday',
  telephone: 'telephone',
  shoe_size: 'shoe_size',
  payment_status: 'payment_status',
  shirt_size: 'shirt_size',
  emergency_name: 'emergency_name',
  emergency_phone: 'emergency_phone',
  order_id: 'order_id',
  order_total: 'order_total',
  order_refund_protect_amount: 'order_refund_protect_amount',
  order_coupon_codes: 'order_coupon_codes',
  order_created_at: 'order_created_at',
  order_status: 'order_status',
};

const descriptors: {
  [K in Cols]: FilterListDescriptor;
} = {
  bib_number: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      default: true,
      reorderOnSearch: true,
      defaultBy: 'DESC',
      calcOrderBy: () => 'bib_number',
    },
  },

  added_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: true,
  },

  added_by: {
    filter: defaultListFilters.none,
  },

  'team.name': {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  email: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  firstname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  lastname: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  gender: gender,

  address: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  country: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  city: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  wave_id: waveId,
  class_id: classId,
  discipline_id: disciplineId,
  country_id: countryId,
  nationality_id: nationalityId,
  payment_status: {
    filter: defaultListFilters.none,
  },

  post_code: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  company: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  union: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  birthday: {
    filter: defaultListFilters.dateRange,
    frontend: {
      type: 'dateRange',
      props: () => ({ withYearSelect: true, dateFormat: 'datetime' }),
    },
  },

  telephone: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  shoe_size: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  shirt_size: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  emergency_name: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  emergency_phone: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  order_refund_protect_amount: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  order_id: {
    filter: defaultListFilters.numberRange,
    frontend: { type: 'numberRange' },
    sort: {
      calcOrderBy: () => 'payment_info:id|id',
      reorderOnSearch: true,
    },
  },

  order_coupon_codes: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  order_created_at: {
    filter: defaultListFilters.dateRange,
    frontend: { type: 'dateRange' },
    sort: {
      calcOrderBy: () => 'order_created_at',
      reorderOnSearch: true,
    },
  },

  order_total: {
    filter: defaultListFilters.priceRange,
    frontend: { type: 'numberRange' },
    sort: {
      defaultBy: 'DESC',
      calcOrderBy: (name) => name,
    },
  },

  order_status: state,

  comment: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },

  external_swimrun_id: {
    filter: defaultListFilters.string,
    frontend: { type: 'string' },
  },
};

export const membersFiltersDescriptors = Object.keys(descriptors).reduce((acc, desc) => {
  return { ...acc, [desc]: new FilterDescriptorWrapper(desc, descriptors[desc as Cols]) };
}, {});
