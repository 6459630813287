import { Box, Button } from '@mui/material';
import classNames from 'classnames';
import { cloneDeep, debounce, forEach, get } from 'lodash';
import { observer } from 'mobx-react';
import { validateForm } from 'modules/Distances/actions/validateForm';
import SavingIndicator from 'modules/Races/components/Edit/Toolbar/SavingIndicator';
import { isCalendarRaceType } from 'modules/Races/utils/isCalendarRaceType';
import qs from 'query-string';
import * as React from 'react';
import { generatePath, withRouter } from 'react-router-dom';
import shortid from 'shortid';

import {
  CONFIRM_POPUP_TYPES,
  DISTANCE,
  DISTANCE_STEPS,
  distanceStep,
  HELPER_DISTANCE,
  HELPER_RACE,
  PERSIST_DISTANCE as action,
  POSSIBLE_CALENDAR_DISTANCE_STEPS,
  ROUTES,
} from 'src/constants';

import { withErrorClean, withProgressSpinner } from 'hocs';

import { Hide } from 'components/Condition';
import { ConfirmationModal } from 'components/ConfirmationModal';
import VerticalStepper from 'components/Stepper/VerticalStepper';

import { isOneFieldValid, messages, newOneFieldValidation, reorderUtil, t } from 'utils';

import { apiLoad } from 'actions/messages/apiLoad';

import { confirmationModalStore, errorsStore, helperDistancesStore, helperRacesStore, progressStore } from 'stores';

import { DISTANCE_FORM_ERRORS, LOAD_UPDATE_FORM, SAVE_CHECKPOINTS } from '../../constants';

import { clearNamespaceErrors, getStepErrors, mapCheckPoints, redirectAfterUpdate, remapIndexes } from '../../utils';

import {
  liveUpdateField,
  mountUpdateDistance,
  scrollToErrorField,
  stepValidationSchema,
  submitSelfServices,
  unmountUpdateDistance,
  validateOneStep,
} from '../../actions';

import updateDistanceService from '../../services/update';

import { currentStepStore, medicalAssistants } from '../../stores';

import { State, stateRelations } from '../../shared/stateHelpers';
import steps, {
  DATA_NORMALIZER_FROM_BACKEND,
  generatedTabOptionsBasedOnData,
  generateRegistrationFields,
  generateRegistrationFieldsBasedOnData,
  STEP_TITLES,
} from '../shared/Steps';
import { Toolbar } from '../shared/Toolbar';

type Props = {} & RouterProps;

const baseStepAction = `${action}_${DISTANCE_STEPS.nameAndDescription}`;
@withRouter
@withProgressSpinner(`LOAD_${HELPER_RACE}`)
@withProgressSpinner(`LOAD_${HELPER_DISTANCE}`)
@withProgressSpinner(LOAD_UPDATE_FORM)
@withErrorClean(DISTANCE_FORM_ERRORS)
@observer
class UpdateDistance extends React.Component<Props, State> {
  formRef = React.createRef<HTMLFormElement>();

  state: State = {
    distance: {
      name: '',
      description: '',
      distance_mode: undefined,
      helper_text: '',
      registration_starts_at: '',
      registration_ends_at: '',
      race_date: '',
      ends_at: '',
      type: '',
      start_type: '',
      max_members_quantity: undefined,
      min_members_quantity: undefined,
      team_relay: false,
      is_for_kids: 0,
      allow_no_profile_registration: false,
      price: 0,
      race_length: 0,
      race_qty: null,
      mark_racers_come: 0,
      show_grayed_out_racers: 0,
      allow_invite: 0,
      location: '',
      id: 0,
      is_visible: true,
      vat_percents: '',
      refund_protect_enabled: false,
      email_content: {
        content: '',
      },
      registration_range: {},
      is_edit_registration_available: false,
      edit_registration_settings: {
        from: '',
        to: '',
      },
      is_transfer_registration_available: false,
      isTransferRegistrationEdit: false,
      isEditRegistrationEdit: false,
      transfer_registration_settings: {
        from: '',
        to: '',
        price: '',
      },
      racers_count: 0,
      raceband_used: false,
    },
    step: {
      value: DISTANCE_STEPS.nameAndDescription,
      index: 0,
    },
    registrationFields: generateRegistrationFields(),
    editorSettings: {
      tab_options: [DISTANCE_STEPS.nameAndDescription, DISTANCE_STEPS.distanceDetails],
    },
    checkpoints: [],
    classes: [],
    prices: [],
    waves: [],
    custom_fields: [],
    disciplines: [],
    confirmPopup: null,
    medical_assistants: [],
    timing_assistants: [],
    goal: {
      goal_type: null,
    },
    isDirty: false,
  };

  private pendingUpdates: Record<string, any> = {};

  componentDidMount() {
    mountUpdateDistance();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location?.search) {
      this.parseStepperParams();
    }
    if (medicalAssistants.selectedValues.length) {
      this.setState({
        ...this.state,
        medical_assistants: [...this.state.medical_assistants, ...(medicalAssistants.selectedValues as any)],
      });

      medicalAssistants.clearSelected();
    }
    const distance = helperDistancesStore.selected;
    const distanceId = distance && distance.id;
    const { id } = this.state.distance;
    if (distanceId && distanceId !== id) {
      this._mapLoadedDataToState();
    }
  }

  private parseStepperParams = () => {
    const {
      distance: { id },
    } = this.state;

    const tab_options = Array.isArray(this.state.editorSettings.tab_options)
      ? this.state.editorSettings.tab_options
      : Object.values(this.state.editorSettings.tab_options);

    if (!Boolean(id)) return;

    const params = qs.parse(this.props.location?.search ?? '');
    const currentStep = get(params, 'step', DISTANCE_STEPS.nameAndDescription) as distanceStep;

    if (tab_options.includes(currentStep)) {
      currentStepStore.set({
        value: currentStep,
        index: tab_options.indexOf(currentStep),
      });
      this.setState({
        step: {
          value: currentStep,
          index: tab_options.indexOf(currentStep),
        },
      });
    } else {
      this.props.history.replace({
        pathname: this.props.location?.pathname,
        search: '',
      });
    }
  };

  componentWillUnmount() {
    this.processPendingUpdates.flush();
    unmountUpdateDistance();
  }

  onSubmit = async (shouldRedirectToTranslations = false, shouldRedirectToGPX = false) => {
    const data = this.state;
    const race = helperRacesStore.selected;
    errorsStore.clear(DISTANCE_FORM_ERRORS);
    if (!validateForm(data)) {
      scrollToErrorField();
      return;
    }
    data.checkpoints = this.setCheckpoins(data.checkpoints);
    data.distance.raceband_used = Boolean(data.distance.raceband_used);

    const isValidSelfServices = await submitSelfServices(data);
    if (!isValidSelfServices) return;

    updateDistanceService.submitForDistanceUpdate(data, race, shouldRedirectToTranslations, shouldRedirectToGPX);
  };

  saveAndExit = () => {
    const data = this.state;
    const race = helperRacesStore.selected;
    errorsStore.clear(DISTANCE_FORM_ERRORS);
    if (!validateForm(data)) {
      scrollToErrorField();
      return;
    }
    race && redirectAfterUpdate(race.id);
  };

  setCheckpoins = (checkpoints: any) => {
    let copy = cloneDeep(checkpoints);

    copy = copy.sort((e, e1) => e.index - e1.index);
    copy = remapIndexes(copy);
    copy = mapCheckPoints(copy);

    return copy;
  };

  onCancel = () => {
    this.setState({
      confirmPopup: this.onRedirectBack.bind(this),
    });
  };

  onRedirectBack = () => {
    const id = helperRacesStore.selected && helperRacesStore.selected.id;
    const { history } = this.props;
    const path = generatePath(ROUTES.raceRoute, { id });
    history.push(path);
  };

  // Stepper
  onChangeStep = (value: distanceStep) => {
    if (this.formRef.current !== null) {
      // Gives browsers time to paint newly changed step before scrolling inner form scroll.
      window.requestAnimationFrame(() => this.formRef.current?.scrollTo(0, 0));
    }
    const { editorSettings, isDirty } = this.state;
    const { history } = this.props;
    const steps = editorSettings.tab_options;
    const newIndex = steps.indexOf(value);

    if (isDirty && value === 'translations') {
      return confirmationModalStore.openModal({
        title: t.staticAsString('distances.confirmPopup.updateTitle'),
        body: t.staticAsString('distances.confirmPopup.updateBody'),
        type: CONFIRM_POPUP_TYPES.confirm,
        onConfirm: () => {
          this.onSubmit(true);
        },
      });
    }

    if (isDirty && value === 'GPX') {
      return confirmationModalStore.openModal({
        title: t.staticAsString('distances.confirmPopup.updateTitle'),
        body: t.staticAsString('distances.confirmPopup.updateBodyGPX'),
        type: CONFIRM_POPUP_TYPES.confirm,
        onConfirm: () => {
          this.onSubmit(false, true);
        },
      });
    }

    history.replace({
      pathname: this.props.location?.pathname,
      search: value === DISTANCE_STEPS.nameAndDescription ? '' : qs.stringify({ step: value }),
    });
    currentStepStore.set({
      value,
      index: newIndex,
    });
    this.setState({
      ...this.state,
      step: {
        value,
        index: newIndex,
      },
    });
  };

  changeStep = (action: 'inc' | 'dec') => {
    const { editorSettings } = this.state;
    const steps = editorSettings.tab_options;
    const { index } = this.state.step;
    const step = this.state.step.value;
    const data = this.state;

    const nextStep = steps[index + 1] as distanceStep;
    const prevStep = steps[index - 1] as distanceStep;

    switch (action) {
      case 'inc':
        if (!validateOneStep(step as distanceStep, data)) {
          return;
        }
        this.onChangeStep(nextStep);
        break;
      case 'dec':
        this.onChangeStep(prevStep);
        break;
      default:
        return;
    }
  };

  onChange = async (
    changes: {
      [K in string]: any;
    },
    nestedKey: string,
    callback: any | Function = () => {},
    isDirty = true,
  ) => {
    if (!nestedKey) {
      return this.setState(
        {
          ...this.state,
          isDirty,
          ...changes,
        },
        () => {
          callback();
        },
      );
    }
    let checkpoints = this.state.checkpoints;
    if (nestedKey === 'editorSettings' && checkpoints.length === 0) {
      const { tab_options } = changes;
      const curr = this.state.editorSettings.tab_options;
      if (!curr.includes('checkpoints') && tab_options.includes('checkpoints')) {
        const raceDefaultLang = helperRacesStore.modelSelected?.value.pref_lang_code ?? 'en';
        progressStore.log('MESSAGES_FETCHING', 'progress');
        await apiLoad(raceDefaultLang);
        const keys = messages.fetch(raceDefaultLang, 'en') || messages.fetch('en', 'en')!;
        progressStore.log('MESSAGES_FETCHING', 'completed');
        const start: any = {
          index: 0,
          assistants: [],
          length: 1,
          radius: 11,
          name: keys['distances.steps.checkpointsForm.firstCheckpoint'],
          __id: shortid(),
        };
        const finish: any = {
          index: 1,
          assistants: [],
          length: this.state.distance.race_length,
          radius: 11,
          name: keys['distances.steps.checkpointsForm.lastCheckpoint'],
          __id: shortid(),
        };
        checkpoints = [start, finish];
      }
    }
    this.setState(
      {
        ...this.state,
        isDirty,
        checkpoints,
        [nestedKey]: {
          ...changes,
        },
      },
      () => {
        callback();
      },
    );
  };

  validateLiveUpdate = (data) => {
    const step = this.state.step.value;
    const formData = this.state;

    if (step === DISTANCE_STEPS.distanceDetails) {
      const dataForValidation = { ...formData, ...data, distance: { ...formData.distance, ...data } };
      return validateOneStep(DISTANCE_STEPS.distanceDetails, dataForValidation);
    }
    const schema = stepValidationSchema(step as distanceStep, formData);

    const result = Object.keys(data).map((fieldName) => {
      if (fieldName === 'custom_fields') {
        return validateOneStep(DISTANCE_STEPS.custom_fields, {
          ...formData,
          ...data,
        });
      } else {
        newOneFieldValidation(DISTANCE_FORM_ERRORS, data, { [fieldName]: schema['_validation'][fieldName] });
        return isOneFieldValid(DISTANCE_FORM_ERRORS, fieldName);
      }
    });
    return result.every((item) => item);
  };

  handleLiveUpdate = (data: AnyObject, isDebounce = false) => {
    Object.keys(data).forEach((key) => {
      this.pendingUpdates[key] = data[key];
    });

    if (isDebounce) {
      this.processPendingUpdates();
      return;
    }

    liveUpdateField(data, this.state)
      .then((distance) => {
        this._updateState(distance);
      })
      .catch(() => {
        progressStore.log(SAVE_CHECKPOINTS, 'failed');
      });
  };

  private processPendingUpdates = debounce(() => {
    const updates = { ...this.pendingUpdates };
    this.pendingUpdates = {};

    liveUpdateField(updates, this.state)
      .then((distance) => {
        this._updateState(distance);
      })
      .catch(() => {
        progressStore.log(SAVE_CHECKPOINTS, 'failed');
      });
  }, 3000);

  _updateState = (distance) => {
    if (distance) {
      const { classes, waves, disciplines, checkpoints, custom_fields } = distance;

      classes && this.setState({ classes: distance.classes as ClassType[] });
      waves && this.setState({ waves: distance.waves as WaveType[] });
      disciplines && this.setState({ disciplines: distance.disciplines as DisciplineType[] });

      if (checkpoints) {
        this.setState({ checkpoints: distance.checkpoints as CheckpointType[] });
        progressStore.log(SAVE_CHECKPOINTS, 'completed');
      }

      if (custom_fields) {
        const { custom_fields: customFieldsFromState } = this.state;
        const updatedCustomFields = reorderUtil
          .changeIndexLayer<CustomFieldType>(custom_fields, 'index', true)
          .map<CustomFieldType>((field: CustomFieldType) => {
            let values;

            if (field.values) {
              values = reorderUtil.changeIndexLayer<CustomFieldValue>(field.values, 'index', true).map((value) => ({
                ...value,
                ...(customFieldsFromState[field.index].values?.[value.index].__id
                  ? { __id: customFieldsFromState[field.index].values?.[value.index].__id }
                  : {}),
              }));
            }

            return {
              ...field,
              is_required: Number(field.is_required),
              values,
              ...(customFieldsFromState[field.index].__id ? { __id: customFieldsFromState[field.index].__id } : {}),
            };
          });

        this.setState({ custom_fields: updatedCustomFields });
      }
    }
  };

  _isContinueAvailable = (): boolean => {
    const errors = errorsStore.errors.validation[`${action}_${DISTANCE_STEPS[this.state.step.value]}`];

    if (!errors) {
      return true;
    }

    return !errors.flatErrors().length;
  };

  _clearConfirmPopup = () => {
    this.setState({ confirmPopup: null });
  };

  _isLastStep = () => {
    const {
      step: { index },
      editorSettings: { tab_options },
    } = this.state;
    return index === tab_options.length - 1;
  };

  _isFirstStep = () => {
    const { index } = this.state.step;
    return index === 0;
  };

  renderStep = () => {
    const { step } = this.state;
    const Step = steps[step.value];
    return (
      <Step
        formData={this.state}
        onChange={this.onChange}
        helperData={{ race: helperRacesStore.modelSelected }}
        controls={this._renderControls()}
        liveUpdate={this.handleLiveUpdate}
      />
    );
  };

  _renderControls = () => {
    const isContinueAvailable = progressStore.isLoading(action);
    return (
      <div className='controls'>
        <div className='left-btn-group'>
          <Hide if={this._isLastStep()}>
            <Button className='secondary' onClick={this.saveAndExit} disableTouchRipple>
              {t.staticAsString('shared.saveAndExit')}
            </Button>
          </Hide>
        </div>

        <div className='right-btn-group'>
          <Hide if={this._isFirstStep()}>
            <Button
              className='cancel'
              onClick={() => {
                this.changeStep('dec');
              }}
              disableTouchRipple
            >
              {t.staticAsString('distances.steps.back')}
            </Button>
          </Hide>
          <Button
            disabled={isContinueAvailable}
            className={classNames('continue', {
              disabled: isContinueAvailable,
            })}
            onClick={
              this._isLastStep()
                ? () => this.onSubmit(false)
                : () => {
                    this.changeStep('inc');
                  }
            }
            disableTouchRipple
          >
            {t.staticAsString(this._isLastStep() ? 'shared.saveAndExit' : 'distances.steps.continue')}
          </Button>
        </div>
      </div>
    );
  };

  // WARNING, this performs validation after data mapping complete!!!
  _mapLoadedDataToState = () => {
    const distance = helperDistancesStore.selected;
    if (!distance) {
      return;
    }

    let newState: State = { ...this.state };
    let newDistance: AnyObject | any = { ...this.state.distance };

    const stateKeys = Object.keys(newState);
    const distanceKeys = Object.keys(newDistance);

    stateKeys.forEach((stateKey: string | any) => {
      const relationKey = stateRelations[stateKey];
      if (relationKey && (distance as AnyObject)[relationKey]) {
        const formatter = DATA_NORMALIZER_FROM_BACKEND[relationKey];
        newState[stateKey] = formatter ? formatter(distance[relationKey]) : distance[relationKey];
      }
    });

    distanceKeys.forEach((distanceKey) => {
      newDistance[distanceKey] = distance[distanceKey];
    });

    newState.distance = newDistance;

    const generatedTabOptions = generatedTabOptionsBasedOnData(cloneDeep(newState));

    if (isCalendarRaceType.get()) {
      newState.editorSettings.tab_options = newState.editorSettings.tab_options.filter((option) =>
        POSSIBLE_CALENDAR_DISTANCE_STEPS.includes(option as distanceStep),
      );
    }

    if (!generatedTabOptions.includes(DISTANCE_STEPS.distanceDetails)) {
      generatedTabOptions.push(DISTANCE_STEPS.distanceDetails);
    }

    const filter = Object.keys(DISTANCE_STEPS);
    generatedTabOptions.sort((a, b) => filter.indexOf(a) - filter.indexOf(b));
    newState.editorSettings.tab_options.sort((a, b) => filter.indexOf(a) - filter.indexOf(b));

    const tabOptions = Array.isArray(newState.editorSettings.tab_options)
      ? newState.editorSettings.tab_options
      : Object.values(newState.editorSettings.tab_options);

    const isTabOptionsInvalid =
      !tabOptions ||
      tabOptions.length <= 1 ||
      tabOptions.length < generatedTabOptions.length ||
      (!tabOptions.includes('refund_protect') && distance.prices?.length);

    if (isTabOptionsInvalid) {
      newState.editorSettings.tab_options = generatedTabOptions;
    }

    if (
      generatedTabOptions.includes(DISTANCE_STEPS.translations) &&
      !newState.editorSettings.tab_options.includes(DISTANCE_STEPS.translations)
    ) {
      newState.editorSettings.tab_options = [...newState.editorSettings.tab_options, DISTANCE_STEPS.translations];
    }

    newState.registrationFields = generateRegistrationFieldsBasedOnData(newState as any);
    newState.custom_fields = reorderUtil.changeIndexLayer<CustomFieldType>(newState.custom_fields, 'index', true);

    newState.custom_fields = newState.custom_fields.map<CustomFieldType>((field: CustomFieldType) => {
      let values;

      if (field.values) {
        values = reorderUtil.changeIndexLayer<CustomFieldValue>(field.values, 'index', true);
      }

      return {
        ...field,
        is_required: Number(field.is_required),
        values,
      };
    });

    newState.medical_assistants = (distance as AnyObject).medical_assistants;
    this.setState(newState as any, () => {
      this.parseStepperParams();
    });
  };

  _breadcrumbs = () => {
    const race = helperRacesStore.modelSelected;
    const distance = helperDistancesStore.modelSelected;

    if (!race) {
      return [];
    }

    if (!distance) {
      return [
        {
          path: `${ROUTES.racesRoute}/${race.value.id}`,
          label: race.value.name || '',
        },
      ];
    }

    return [
      {
        path: `${ROUTES.racesRoute}/${race.value.id}`,
        label: race.value.name || '',
      },
      { label: distance.value.name || '' },
    ];
  };

  render() {
    // Necessary for react-mobx`s observer
    // eslint-disable-next-line
    const distance = helperDistancesStore.selected;

    const { step, editorSettings, confirmPopup } = this.state;
    const stepTitleTranslateKey = STEP_TITLES[step.value] || ' ';
    const race = helperRacesStore.modelSelected;

    const steps = Array.isArray(editorSettings.tab_options) ? editorSettings.tab_options : Object.values(editorSettings.tab_options);

    if (!race || !distance) {
      return null;
    }

    return (
      <div className='content main-distance-form '>
        <Box display='flex' justifyContent='space-between'>
          <Toolbar items={this._breadcrumbs()} />
          <SavingIndicator action={`UPDATE_${DISTANCE}`} />
        </Box>

        <div className='form-wrap'>
          <VerticalStepper stepModel='distances' step={step} steps={steps} onChange={this.onChangeStep} errors={getStepErrors()} />
          <form ref={this.formRef} className='distance-form new-distance' autoComplete='off'>
            <h3 className='step-title'>{t(stepTitleTranslateKey)}</h3>
            {this.renderStep()}
          </form>
        </div>
        <ConfirmationModal
          open={!!confirmPopup}
          onConfirm={confirmPopup || ((() => {}) as any)}
          onClose={this._clearConfirmPopup}
          title={t.staticAsString('distances.confirmCloseTitle') as any}
          body={t.staticAsString('distances.editConfirmCloseBody') as any}
          type={CONFIRM_POPUP_TYPES.confirm}
        />
      </div>
    );
  }
}

export { action };
export { UpdateDistance };
