import Typography from '@mui/material/Typography';
import { isUndefined, isEmpty, assign } from 'lodash';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { CFwithRegisteredUsers } from 'modules/Distances/actions';
import { ERRORS_PREFIXES } from 'modules/Distances/constants';
import { havePaidCustomFields } from 'modules/Distances/utils';
import { clearNamespaceErrors } from 'modules/Distances/utils/errors';
import * as React from 'react';
import shortid from 'shortid';

import { DISTANCE_STEPS, PERSIST_DISTANCE as action, DRAG_IDS, CONFIRM_POPUP_TYPES, DISTANCE } from 'src/constants';

import { withErrorClean } from 'hocs';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { DNDContainer } from 'components/DragNDrop';
import { Icon } from 'components/Icon';

import { reorderUtil, t } from 'utils';

import { Race as RaceModel } from 'models';

import { progressStore } from 'stores';

import { State as OriginalFormState } from '../../../../shared/stateHelpers';
import { Item } from './Item';
import { normalizeDataForBackend as customFieldOptionNormalizeDataForBackend } from './Item';

type Props = {
  formData: OriginalFormState;
  onChange: (
    arg0: {
      [K in string]: any;
    },
    nestedKey: string,
    callback?: Function,
    isDirty?: boolean,
  ) => void;
  helperData: {
    race: nil | RaceModel;
  };
  controls: React.ReactNode;
  liveUpdate: (value: AnyObject, isDebounce?: boolean) => void;
};

type State = {
  openedItemId: string | number | null;
  initial: boolean;
  onConfirm: Function | null;
  confirmTitle: string;
  confirmBody: string;
  confirmType: string;
};

const stepAction = `${action}_${DISTANCE_STEPS.custom_fields}`;

@withErrorClean(stepAction)
@observer
class CustomFields extends React.Component<Props, State> {
  state = {
    openedItemId: null,
    initial: true,

    onConfirm: null,
    confirmTitle: '',
    confirmBody: '',
    confirmType: '',
    contact_emails: [],
  };

  componentDidMount() {
    this._addDefaultValues();
    CFwithRegisteredUsers();
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.initial) {
      const { custom_fields } = props?.formData;
      const customFields = filterDeleted(custom_fields);

      if (!isEmpty(customFields[0])) {
        return {
          initial: false,
          openedItemId: customFields[0]?.__id,
        };
      }
    }

    return null;
  }

  onChange = (values: Array<CustomFieldType>, callback: Function = () => {}, isNeedLiveUpdate = true) => {
    const { formData, onChange, liveUpdate } = this.props;
    const { distance, editorSettings } = formData;
    let tabOptions = [...editorSettings.tab_options];

    const havePaidFields = havePaidCustomFields(values);
    const isRefundAllowed = tabOptions.includes(DISTANCE_STEPS.refund_protect);
    const isPaidDistance = tabOptions.includes(DISTANCE_STEPS.prices);
    let liveUpdateValue: AnyObject = {};

    if (havePaidFields && !isRefundAllowed) {
      tabOptions = [...tabOptions, DISTANCE_STEPS.refund_protect];
      distance.refund_protect_enabled = true;
      liveUpdate({ refund_protect_enabled: true });
    }

    if (!havePaidFields && isRefundAllowed && !isPaidDistance) {
      tabOptions = tabOptions.filter((tab) => tab !== DISTANCE_STEPS.refund_protect);
      distance.refund_protect_enabled = false;
      liveUpdate({ refund_protect_enabled: false });
    }

    isNeedLiveUpdate &&
      liveUpdate({ ...liveUpdateValue, editorSettings: { ...editorSettings, tab_options: tabOptions }, custom_fields: values }, true);

    onChange(
      {
        distance: { ...distance },
        editorSettings: { ...editorSettings, tab_options: tabOptions },
        custom_fields: values,
      },
      '',
      () => {
        callback();
      },
      false,
    );
  };

  onAddNew = (e: React.SyntheticEvent) => {
    if (progressStore.isLoading(`UPDATE_${DISTANCE}`)) return;

    e.preventDefault();
    const { custom_fields } = this.props.formData;
    const newField = getCustomField();

    let values = [...custom_fields, newField];

    values = reorderUtil.changePosition(values, custom_fields.length, 0);

    this.toggleCollapseItem(newField.__id || '');

    this.onChange(
      values,
      () => {
        clearNamespaceErrors(ERRORS_PREFIXES.custom_fields);
      },
      false,
    );
  };

  onRemove = (id: number | string) => {
    const { custom_fields } = this.props.formData;
    const values = custom_fields
      .filter((customField) => !customField._delete)
      .reduce((acc: any, customField: any) => {
        if (customField.id === id) {
          return [
            ...acc,
            {
              ...customField,
              _delete: true,
            },
          ];
        } else if (customField.__id === id) {
          return acc;
        } else {
          return [...acc, customField];
        }
      }, []);

    this.onChange(values, () => {
      clearNamespaceErrors(ERRORS_PREFIXES.custom_fields);
    });
    this._clearPopup();
  };

  handleRemoveWrapper = (id: number | string) => {
    const { custom_fields } = this.props.formData;
    const fieldToRemove = custom_fields.find((field) => {
      return (field.id || field.__id) === id;
    });

    const withConfirm = fieldToRemove?.type || fieldToRemove?.is_required !== -1 || fieldToRemove?.name || fieldToRemove?.helper_text;

    if (withConfirm) {
      this.setState({
        onConfirm: this.onRemove.bind(this, id),
        confirmBody: t.staticAsString('distances.steps.customFieldsForm.deleteConfirmation') as any,
        confirmTitle: t.staticAsString('races.confirmPopup.mainTitle') as any,
        confirmType: CONFIRM_POPUP_TYPES.confirm,
      });
    } else {
      this.onRemove(id);
    }
  };

  handleChangeWrapper = (
    id: number | string,
    changedCustomField: CustomFieldType,
    callback: Function = () => {},
    withConfirm?: boolean,
  ) => {
    if (withConfirm) {
      return this.setState({
        onConfirm: this.onChangeCustomField.bind(this, id, changedCustomField, callback),
        confirmBody: t.staticAsString('distances.steps.customFieldsForm.deleteOptionConfirmation') as any,
        confirmTitle: t.staticAsString('races.confirmPopup.mainTitle') as any,
        confirmType: CONFIRM_POPUP_TYPES.confirm,
      });
    }

    this.onChangeCustomField(id, changedCustomField, callback);
  };

  toggleCollapseItem = (id: number | string): void => {
    const { openedItemId } = this.state;
    const newValue = openedItemId === id ? null : id;

    this.setState({
      openedItemId: newValue,
    });
  };

  onChangeCustomField = (id: number | string, changedCustomField: CustomFieldType, callback: Function = () => {}) => {
    const { onConfirm } = this.state;
    const { custom_fields } = this.props.formData;

    const newData = custom_fields.map((customField) =>
      customField.id === id || customField.__id === id ? changedCustomField : customField,
    );

    this.onChange(newData, callback);

    if (onConfirm) {
      this._clearPopup();
    }
  };

  onDragEnd = (result: AnyObject): void => {
    const { custom_fields } = this.props.formData;

    // dropped outside the list
    if (!result?.destination) {
      return;
    }

    const items = reorderUtil.changePosition([...custom_fields], result.source.index, result.destination.index);

    this.onChange(items);
  };

  _renderCustomFields = (): React.ReactNode => {
    const { formData } = this.props;
    const { openedItemId } = this.state;
    const { custom_fields } = formData;
    const currency = this._currency();
    const valuesForRender = filterDeleted(custom_fields);

    return valuesForRender.map((customField, index) => {
      const actualId = isUndefined(customField.id) ? customField.__id || '' : customField.id;

      return (
        <Item
          index={index}
          key={actualId}
          value={customField}
          open={openedItemId === (customField.__id || actualId)}
          toggleCollapse={() => this.toggleCollapseItem(customField.__id || actualId)}
          onChange={this.handleChangeWrapper}
          onRemove={this.handleRemoveWrapper}
          currency={currency}
        />
      );
    });
  };

  _addDefaultValues = () => {
    const { custom_fields } = this.props.formData;
    const { onChange } = this.props;

    if (!custom_fields.filter((el) => !el._delete).length) {
      onChange({ custom_fields: [getCustomField(true)] }, '', () => {});
    }
  };

  _clearPopup = () => {
    if (this.state.onConfirm) {
      this.setState({
        onConfirm: null,
      });
    }
  };

  _currency = (): string => {
    const { race } = this.props.helperData;
    if (!race) {
      return '';
    }

    const currency = race.currency();
    return currency ? `(${currency})` : '';
  };

  render() {
    const { onConfirm, confirmBody, confirmTitle, confirmType } = this.state;

    return (
      <div className='custom-field-fields-form form-content-wrapper'>
        <ConfirmationModal
          open={!!onConfirm}
          onConfirm={onConfirm || (() => {})}
          onClose={this._clearPopup}
          title={confirmTitle}
          body={confirmBody}
          type={confirmType}
        />
        <div className='add-new' onClick={this.onAddNew}>
          <Icon value='add-small' className='icon' />
          <Typography className='label'>{t.staticAsString('distances.steps.customFieldsForm.add')}</Typography>
        </div>
        <div className='separator' />
        <DNDContainer droppableId={DRAG_IDS.CUSTOM_FIELDS} className='custom-field-list' onDragEnd={this.onDragEnd}>
          {this._renderCustomFields()}
        </DNDContainer>
        {this.props.controls}
      </div>
    );
  }
}

const getCustomField = (isFirst?: boolean, index: number = 0): CustomFieldType | any => {
  return {
    __id: shortid(),
    type: '',
    name: '',
    helper_text: '',
    index,
    is_required: -1,
    is_enabled: true,
    _new: isFirst,
  };
};

const getCustomFieldValue = (isFirst: boolean = false, index: number = 0): CustomFieldValue | any => {
  return {
    __id: shortid(),
    price: null,
    vat_percents: null,
    value: '',
    index,
    _new: isFirst,
  };
};

const filterDeleted = <
  T extends {
    _delete?: boolean;
  },
>(
  values: Array<T>,
): Array<T> => {
  return values.filter((el) => !el._delete);
};

const normalizeDataForBackend = (
  values: CustomFieldType[],
): Array<
  | {
      _delete?: boolean;
      id?: number;
    }
  | CustomFieldType
> => {
  return values.map((el) => {
    if (!!el._delete) {
      return { id: el.id, _delete: true };
    }

    const processedOptions = customFieldOptionNormalizeDataForBackend(el.values);

    return { ...el, ...processedOptions, _delete: false };
  });
};

const cleanStep = (formData: OriginalFormState): OriginalFormState => {
  const { distance, editorSettings } = formData;

  const isPaidDistance = editorSettings.tab_options.includes(DISTANCE_STEPS.prices);

  if (!isPaidDistance) distance.refund_protect_enabled = false;

  return { ...formData, distance };
};

export { CustomFields, getCustomField, getCustomFieldValue, normalizeDataForBackend, cleanStep };
